.uperBlocks {
  width: 42%;
  height: 120px;
  background-color: #fff;
  border: none;
  border-radius: 20px;
  margin-left: 40px;
  position: relative;
  border: 2px solid #30263E0D;
  box-shadow: 0px 10px 30px 0px #30263E0F;
}
.SupportImage {
  position: absolute;
  top: 0;
  right: 0;
  width: 80px;
  height: 80px;
}

.text {
    font-size: 16px;
    margin: 0;
    margin-left: 20px;
    color: gray;
    font-weight:300;
    margin-top: 10px;
}

.headingText {
    font-weight: bold;
    margin-left: 20px;
    font-size: 18px;
    margin-top: 50px;
}

.selectStyle{
  width: 100%;
  height: 40px;
  background-color: white;
  border-radius: 20px;
  margin-left: 40px;
  border: 2px solid #30263E0D;
  box-shadow: 0px 10px 30px 0px #30263E0F;
}

.textAreaStyling {
  height: 120px;
  width: 100%;
  resize: none;
  margin-left: 55px;
  border: 2px solid #30263E0D;
  box-shadow: 0px 10px 30px 0px #30263E0F;
  border-radius: 20px;
}

.submitButton {
  background: #4E4DFF;
  color: white;
  width: 130px;
  height: 40px;
  font-weight: bold;
  /* margin-top: 20px;
  margin-left: 40px; */
  border-radius: 20px;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  border: none !important;
  margin-right: 20px;
}


.formSelect,
.formSelect:hover,
.formSelect:active,
.formSelect:focus,
.formSelect:visited {
  width: 100% !important;
  background-color: white !important;
  border-radius: 35px !important;
  height: 40px !important;
  font-size: 14px !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  border: none !important;
  display: flex !important;
  align-items: center !important;
  /* justify-content: center !important; */
  padding: 0rem 1rem !important;
  border: 3px solid #f5f4f5 !important;
  margin-left: 55px;
}

.formDisp {
  margin: 0% !important;
  padding: none !important;
}

.adminChatBtn {
  background: #30263e;
  color: white;
  font-weight: bold;
  border-radius: 20px;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  height: 40px;
  margin-left: 20px;
  border: none !important
}

.btnDiv {
  width: 100%;
  display: flex;
  margin-left: 50px;
  justify-content: center !important;
  align-items: center !important;
}