.rdp {
  --rdp-cell-size: 50px;
  --rdp-caption-font-size: 18px;
  --rdp-accent-color: rgba(78, 77, 255, 1);
  --rdp-background-color: #e7edff;
  --rdp-accent-color-dark: rgba(78, 77, 255, 1);
  --rdp-background-color-dark: rgba(78, 77, 255, 1);
  --rdp-outline: 2px solid var(--rdp-accent-color);
  --rdp-outline-selected: 3px solid var(--rdp-accent-color);
  --rdp-selected-color: #fff;
}

.range_picker .rdp-nav {
  display: block !important;
  gap: 10px;
}

.range_picker .rdp-caption {
  flex-direction: row-reverse;
  gap: 10px;
}

.range_picker .rdp-nav_button {
  padding: 0;
  box-shadow: 0px 5px 8px 0px rgba(48, 38, 62, 0.1);
  margin-right: 10px;
  width: 40px;
  height: 40px;
}

.sideform {
  margin-top: -25px;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.reasonBox {
  margin: 0;
  margin-top: -5px !important;
}

.radiofit {
  height: 30px;
}
