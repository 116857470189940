.bankInfoOuterContainer {
  width: 80%;
}
.topHeading {
  font-weight: bold;
  margin-top: 30px;
}

.bankType {
  display: flex;
  background-color: black;
  border-radius: 20px;
  height: 30px;
  width: 200px;
  flex-direction: column;
  justify-content: center;
  font-size: 15px;
  text-align: center;
  color: white;
}
.bankInfoEditBtnContainer {
  width: 50px;
  background: #fff;
  border-radius: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}

.bankInfoEditBtnContainerTax {
  /* width: 50px; */
  background: #fff;
  border-radius: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: flex-start;
  gap: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  max-width: 200px;
  font-weight: bold;
  box-shadow: 0px 5px 8px 0px rgba(48, 38, 62, 0.05);
}

.bankInfoEditBtnContainerTax:hover {
  background-color: #30263e !important;
  color: white !important;
}

.bankName {
  display: flex;
  margin: 30px 0px;
  font-size: 20px;
  text-align: center;
  color: black;
  font-weight: bold;
}
.labelConatiner {
  font-size: 8px;
  width: 100%;
  display: flex;
  align-items: start;
  color: grey;
  justify-content: start;
  margin: 20px 0px;
}
.bankInfoAnswerContainer {
  font-size: 15px;
  text-align: justify;
  justify-content: start;
  width: 100%;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin: 20px 0px;
}
.borderDiv {
  border-left: 2px gray solid;
  padding-left: 10px;
  margin-left: -10px;
}
.downBorder {
  border-bottom: 2px gray solid;
}
