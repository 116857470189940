.eventcartcardcontainer {
  border-radius: 18px;
  background: var(--White, #fff);
  box-shadow: 0px 10px 30px 0px rgba(48, 38, 62, 0.06);
  width: 340px;
  height: 135px;
  display: flex;
  justify-content: space-around;
  padding: 12px;
  flex-direction: column;
}
.eventcartcard {
  border-radius: 18px;
  display: flex;
  justify-content: space-between;
}
.eventcartcardcontent {
  display: flex;
  padding: 13px;
}
.eventcartcarddetails {
  margin-top: 0px;
  margin-left: 5px;
}
.eventbuttoncontainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  cursor: pointer;
}
.eventcartcardinformation {
  margin: 0;
  padding: 0;
}
.deletedcartcard {
  position: relative;
  height: 30px;
  background-color: black;
  color: white;
  border-radius: 15px;
  margin-right: 5px;
  border: none;
  cursor: pointer;
  width: 100px;
}
.checkoutcartbutton {
  position: relative;
  height: 30px;
  background: var(--Blue, #4e4dff);
  color: white;
  border-radius: 15px;
  margin-right: 5px;
  border: none;
  cursor: pointer;
  width: 100px;
}
.quantitybuttoncontainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.quantitybuttoncontainer button {
  margin: 0 5px;
  background-color: #4b3f62;
  border: none;
  padding: 5px 10px;
  border-radius: 50%;
  cursor: pointer;
  font-weight: bold;
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, transform 0.2s;
}

.quantitybuttoncontainer button:hover {
  background-color: #f1f1f1;

  color: #30263e;

  transform: scale(1.1);
}
