.cartcardcontainer {
  border-radius: 18px;
  background: var(--White, #fff);
  box-shadow: 0px 10px 30px 0px rgba(48, 38, 62, 0.06);
  width: 340px;
  height: 130px;
  display: flex;
  justify-content: space-around;
  padding: 12px;
}

.cartcardcontent {
  display: flex;
  padding: 13px;
}

.cartcarddetails {
  margin-top: -20px;
  margin-left: 5px;
}

.deletedcartcard {
  background-color: black;
  color: white;
  border-radius: 15px;
  margin-left: 5px;
  border: none;
  padding: 6px;
  cursor: pointer;
}

.checkoutcartbutton {
  color: white;
  background: var(--Blue, #4e4dff);
  border: none;
  margin-left: 5px;
  border-radius: 15px;
  padding: 6px;
  cursor: pointer;
}
