.custom-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.custom-checkbox-hidden {
  opacity: 0;
  position: absolute;
}

.custom-checkbox-checked,
.custom-checkbox-unchecked {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: inline-block;
}

.custom-checkbox-unchecked {
  border: 2px solid #d9d9d9;
  box-sizing: border-box;
}

.custom-checkbox-checked svg {
  fill: url(#paint0_linear);
}

.custom-checkbox-labels {
  display: flex;
  flex-direction: column;
  margin-left: 8px;
}

.custom-checkbox-primary-label {
  font-weight: bold;
}

.custom-checkbox-secondary-label {
  font-size: smaller;
  color: #666;
}
