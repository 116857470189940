.cardCategory {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  border-radius: 30px;
  background-color: #fff;
  position: absolute;
  bottom: -15px;
  left: 15px;
  padding: 8px 15px;
}

.cardRatingServiceCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background-color: #30263e;
  position: absolute;
  top: 10px;
  left: 10px;
  padding-top: 20px;
  padding-left: -10px;
}

.cardActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 10px 15px;
  padding-left: 10px;
  gap: 5px;
}

.serviceCard {
  width: 100%;
  text-decoration: none;
  color: gray;
  min-width: 300px;
  max-width: 400px;
  position: relative;
  gap: 10px;
}

.serviceInfo {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 7px;
}

.cardPricing {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  margin: 5px;
}

.priceVal {
  color: #30263e;
  font-weight: 500;
  margin: 0;
}

.serviceCapsule {
  position: absolute;
  top: 15px;
  right: 10px;
  background: linear-gradient(45deg, rgb(52, 152, 219), rgb(187, 134, 252));
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 5;
  font-weight: bold;
  border: 0.03rem solid white;
}

.serviceCapsuleAwait {
  position: absolute;
  top: 15px;
  left: 10px;
  background: linear-gradient(45deg, rgb(52, 152, 219), rgb(187, 134, 252));
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 5;
  font-weight: bold;
  border: 0.03rem solid white;
}

.serviceCapsuleActive {
  position: absolute;
  top: 15px;
  left: 10px;
  background: rgba(253, 29, 29, 1);

  color: white;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 5;
  font-weight: bold;
  border: 0.03rem solid white;
}

.richDesc {
  color: #30263e;
  line-height: 1rem;
  word-spacing: 0.05rem;
  letter-spacing: 0.025rem;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  height: fit-content;
  max-height: 60px;
}

.mainTitle {
  font-size: 1rem;
  color: #30263e;
  font-weight: 500;
  margin: 0;
  text-align: center;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}