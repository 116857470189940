/* .profileContainer {
  background: #f5f4fa !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
} */
.customLayoutBG {
  background-color: #f5f4fa !important;
}
.parentContentdiv {
  /* display: flex; */
  gap: 1rem;
  /* height: 100vh; */
  /* justify-content: center; */
  /* align-items: center; */
}

.parentContentdiv1 {
  display: flex;
  gap: 1rem;
  /* height: 100vh; */
  justify-content: center;
  align-items: center;

  /* margin-bottom: 5rem; */
  /* border: 1px solid red; */
}
.pbx {
  background-color: white;
  width: 80%;
  height: 40%;
  flex-shrink: 0;
  margin-top: 2rem;
  border-radius: 15px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 1rem;
}
.pbxin {
  width: 90%;
  height: 90%;
  flex-shrink: 0;
  border-radius: 4px;
  background: linear-gradient(
    125deg,
    rgba(136, 0, 255, 0.15) 0.98%,
    rgba(0, 178, 255, 0.15) 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.breadcrumbItem:hover {
  cursor: pointer;
}
.customLayoutBackground {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(185px, 1fr));
  gap: 10px;
  /* flex-direction: "column"; */
  align-items: center;
  justify-content: center;
  height: 500px;
  width: 70%;
}
.rightdivcontent {
  width: 30%;
  height: 100vh;
  display: flex;
  justify-content: center;
  padding-top: 1rem;
}
.container {
  background: #f5f4fa !important;
  display: flex;
  /* gap: 20px; */
  padding: 20px;
  max-width: 100%;
  margin: 0px auto;
  height: 100vh;
  /* overflow-y: auto; */
  box-sizing: border-box;
  /* overflow-y: auto; */
  /*  */
  justify-content: center;
  /* align-items: center; */
  /*  */
  margin-bottom: 5rem;
}

.servicesContainer {
  background: #f5f4fa !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.serviceMainContainer {
  width: 85%;
  margin: 10px auto;
  margin-top: 110px;
}

.customProfileLayoufycugtContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.customProfileLayoutContainerDashboard {
  margin: auto;
}

.continueReading {
  font-size: 18px;
  font-weight: 500;
  text-decoration: underline;
}

.sidebar {
  display: flex;
  flex-direction: column;
  /* gap: 20px; */
  /* margin-top: 5%; */
  align-items: center;
  /*  */
  width: auto;
  height: 100vh;
}
.profileContentOuterBox {
  display: flex;
  width: 100%;

  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 20px;
  flex-direction: column;
  height: 65vh;
}
.sectionTitle {
  font-size: 30px;
  margin-bottom: 10px;
  color: #333;
}

/* .accountSection, */
.accountSection,
.settingsSection {
  /* border: 1px solid orange; */
  display: flex;
  justify-content: center;
  /* align-items: center; */
  /* margin-top: 20px; */
  flex-direction: column;
  width: auto;
}
.accountOptions,
.settingsOptions {
  display: grid;
  grid-template-columns: repeat(4, 185px);
  gap: 20px;
  /* padding: 20px; */
  border: none;
  margin-top: 20px;
  /* border: 1px solid green; */
  width: auto;
  align-items: center;
  justify-content: center;
}

.textdivB {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 1rem;
  width: 145px;
  padding-left: 5px;
}

.option {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 80px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  width: 185px;
  height: 120px;
  flex-shrink: 0;
}

.option:hover {
  transform: translateY(-15px);
  background-color: #ffffff;
}
.option::selection {
  transform: translateY(-15px);
  background-color: #f4f4f4;
}

.optionIcons {
  background: linear-gradient(270deg, #f5f4fa 0%, rgba(245, 244, 250, 0) 100%);
  width: 145px;
  padding: 5px;
  border-radius: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
}
.iconDiv {
  background-color: #f5f4fa;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.goIconDiv {
  background-color: white;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.goIconDiv svg {
  font-size: 16px;
  margin: auto;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.goIconDiv svg path {
  color: black;
}

.option svg {
  margin-right: 8px;
}

/* .profileContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 30%;
  align-items: center;
} */

.profileHeader {
  display: grid;
  width: 100%;
  grid-template-columns: auto 1fr;
  gap: 20px;
  align-items: center;
  border-radius: 20px;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 20px;
  align-items: center;
  /* background: url("../../assests/lineargradient.png"); */
  display: flex;
  flex-direction: column;
  height: 400px;
  justify-content: center;
  /* background: #fff; */
  text-align: center;
}

.profileImage {
  width: 150px;
  height: 150px;
  border-radius: 75px;
  object-fit: cover;
}

.profileName {
  margin: 0;
  color: #333;
  font-size: 24px;
}

.profileDescription {
  margin: 0;
  color: #666;
  font-size: 14px;
}

.myProfileContainer {
  /* margin-top: 50px; */
  display: flex;
}

.backBtnContainer {
  /* height: 70px;
  width: 80px; */
  cursor: pointer;
  height: 30px;
  display: inline-block;
  position: fixed;
  z-index: 1000;
  align-items: start;
}

.breadcrumbContainer {
  background: #fff;
  width: 90%;
  margin: -20px auto 2px -50px;
  z-index: 1000;
  position: fixed;
  min-width: auto;
  padding: 0px 10px;
}
.pdLeftDiv,
.pdRightDiv {
  width: 50%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  gap: 10px;
  /*border: 1px solid red*/
}
@media (max-width: 1200px) {
  .accountOptions,
  .settingsOptions {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
}
.topSectionEdit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 1rem;
}
.editSave {
  background: #4e4dff;
  color: white;
  border-radius: 35px;
  display: flex;
  width: 120px;
  height: 40px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
  font-weight: bold;
  font-size: 16px;
  border: none;
  cursor: pointer;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.partition {
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .container {
    grid-template-columns: 1fr;
  }

  .profileHeader {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .profileImage {
    margin: 0 auto;
  }

  .accountOptions,
  .settingsOptions {
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  }
}

/* ............ */

/* Profile.module.css */
.customLayout {
  background-color: #f5f4fa;
}

/* .profileContent {
  padding: 20px;
} */

.profileCard {
  background: #ffffff;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
}

.idImage {
  width: 100%;
  height: auto;
  margin-top: 10px;
}
.tentacle {
  stroke: black;
  stroke-width: 0.5;
  fill: none;
  stroke-dasharray: 1000;
  stroke-dashoffset: 900;
  animation: draw-erase 10s linear infinite;
  animation-delay: 0ms;
  animation-duration: 5s;
}

@keyframes draw-erase {
  0%,
  100% {
    stroke-dashoffset: 800;
  }
  1% {
    stroke-dashoffset: 810;
  }
  2% {
    stroke-dashoffset: 820;
  }
  3% {
    stroke-dashoffset: 830;
  }
  4% {
    stroke-dashoffset: 840;
  }
  5% {
    stroke-dashoffset: 850;
  }
  6% {
    stroke-dashoffset: 860;
  }
  7% {
    stroke-dashoffset: 870;
  }
  8% {
    stroke-dashoffset: 880;
  }
  9% {
    stroke-dashoffset: 890;
  }
  10% {
    stroke-dashoffset: 900;
  }
  11% {
    stroke-dashoffset: 910;
  }
  12% {
    stroke-dashoffset: 920;
  }
  13% {
    stroke-dashoffset: 930;
  }
  14% {
    stroke-dashoffset: 940;
  }
  15% {
    stroke-dashoffset: 950;
  }
  16% {
    stroke-dashoffset: 960;
  }
  17% {
    stroke-dashoffset: 970;
  }
  18% {
    stroke-dashoffset: 980;
  }
  19% {
    stroke-dashoffset: 990;
  }
  20% {
    stroke-dashoffset: 1000;
  }
}

.profileContent {
  /* margin-top:70px; */
  display: flex;
  flex-direction: column;
  padding: 0.8rem;
  border-radius: 20px;
  width: 140%;
  background-color: #fff;
  align-items: center;
}

.profileHeader {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: 100%;
  border-radius: 20px;
  align-items: center;
  background: linear-gradient(
    to bottom,
    rgb(229, 225, 251) 0%,
    rgb(221, 233, 250) 50%,
    white 100%
  );
  display: flex;
  flex-direction: column;
  height: fit-content;
  justify-content: center;
  text-align: center;
  gap: 0px;
  /* border : 2px solid pink */
}

.profileImageWrapper {
  position: relative;
  width: 90px;
  height: 90px;
  border-radius: 75px;
  /* margin-bottom: 5px; */
  border: 2px solid #fff;
}

.editIconWrapper {
  position: relative;
  top: -30px;
  right: -30px;
  background: rgba(0, 0, 0, 0.5);
  opacity: 80%;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.editIcon {
  color: #fff;
  font-size: 0.9rem;
}

.container {
  background: #f5f4fa !important;
  display: flex;
  margin: 0px auto;
  height: 100vh;
  margin-bottom: 40px;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 5rem;
}

.disabled {
  background-color: #b7b7b7 !important;
  cursor: not-allowed;
  color: #fff;
}

.disabled:hover {
  background-color: #b7b7b7 !important;
  content: "Edit is coming soon";
}

.loadingSpinner {
  color: red !important;
}
