.cardCategory {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  border-radius: 30px;
  background-color: #fff;
  position: absolute;
  bottom: -15px;
  left: 15px;
  padding: 8px 15px;
}
.serviceListing {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  grid-gap: 2rem;
  margin-top: 10px;
  margin-bottom: 20px;
}
.cardRating {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  background-color: #30263e;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 15px;
  padding-left: 10px;
}

.cardActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 10px 15px;
  padding-left: 10px;
  gap: 5px;
  /* border: 1px solid red; */
  border-radius: 35px;
}

.serviceCard {
  width: 100%;
  text-decoration: none;
  color: gray;
  min-width: 300px;
  max-width: 400px;
  position: relative;
  gap: 10px;
}

.serviceInfo {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 7px;
}

.cardPricing {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  /* padding-top: 20px; */
  margin: 30px;
}

.priceVal {
  color: #30263e;
  font-weight: 500;
  margin-top: 5px;
}
.mainTitle {
  margin-top: 8px;
  font-weight: 500;
  font-size: 18px;
}
.description {
  margin-top: 8px;
  margin-left: 0px;
}

.NoDataFound {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 0px;
  height: 100%;
}
.cardActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 10px 15px;
  padding-left: 10px;
  gap: 5px;
}
.NoDataFound {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 0px;
  height: 100%;
}
.postCard {
  border: 1px solid red;
}

.cardWrapper {
  height: fit-content;
  display: flex;
  flex-direction: column;
  border-radius: 25px;
  margin: 0 auto !important;
  padding: 0 !important;
  overflow: hidden;
  background-color: transparent !important;
  width: 100%;
  cursor: pointer !important;
  border: none !important;
  border-bottom: 1px solid #d8f3ff;
  border: 1px solid red;
}

.cardImageWrapper {
  position: relative;
  width: 100%;
}

.coverImage {
  width: 100%;
  object-fit: cover;
  max-height: 500px;
  min-height: 365px;
  border-radius: 25px !important;
}

.rightOverlay {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 10%;
  padding: 10px;
  /* background: rgba(0, 0, 0, 0.3); */
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.profileInfoContainer {
  margin-bottom: auto;
  text-align: center;
}

.profileDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.profilePic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid white;
  object-fit: cover;
}

.profilePicPlaceholder {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: white;
  border: 2px solid white;
}

.profileName {
  font-weight: bold;
  color: #30263e;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.postDate {
  font-size: 12px;
  color: #ccc;
  margin-top: 5px;
}

.iconContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.iconBtn {
  position: relative;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.countOnIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(92.54deg, #8800ff, #00b2ff);
  color: #fff;
  font-weight: bold;
  z-index: 2;
  width: 18px;
  height: 18px;
  font-size: 12px;
  border-radius: 50%;
  position: absolute;
  top: -6px;
  right: -6px;
}

.serviceListing {
  display: flex;
  flex-direction: column;
  width: 95%;
  margin: auto;
  gap: 1rem;
  margin-bottom: 1rem;
}

.bottomIconsContainer {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem;
  border-radius: 10px;
}

.carousel {
  /* border: 1px solid red; */
  width: 100% !important;
}

.profileInfoStrip {
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 0.5rem; */
  border-radius: 10px;
  /* background-color: #f1f1f1; */
}

.profileDetailsDrawer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.iconCapsule {
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
}

.icon {
  width: 24px;
  height: 24px;
  filter: grayscale(100%);
  position: relative;
}

.icon.liked {
  filter: grayscale(0%);
}

.likeBtn {
  /* border: 1px solid red; */
  /* position: relative; */
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-left: 80%;
}

/* here */

.analyticsWrapper {
  padding: 20px;
}

.analyticsCard {
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 15px;
}

.cardContent {
  display: flex;
  align-items: center;
  gap: 10px;
}

.icon {
  font-size: 28px;
  color: #30263e;
  position: absolute;
  z-index: 2;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
}

.analyticsTitle {
  margin: 0;
  font-weight: bold;
  color: #30263e;
}

.analyticsNumber {
  margin: 0;
  font-size: 18px;
  color: #666;
}

.sectionTitle {
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: bold;
  color: #30263e;
}

.userList,
.commentList {
  margin-top: 10px;
}

.userCard,
.commentCard {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.userProfilePic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.userDetails {
  display: flex;
  flex-direction: column;
}

.userName {
  font-size: 14px;
  font-weight: bold;
}

.userRole {
  font-size: 12px;
  color: #666;
  margin-left: 0.5rem;
}

.commentContent {
  font-size: 14px;
  color: #666;
  margin-top: 2px;
}

.commentCard {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.userProfilePic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.commentDetails {
  display: flex;
  flex-direction: column;
}

.userName {
  font-size: 14px;
  font-weight: bold;
  margin: 0;
}

.userRole {
  font-size: 12px;
  color: #666;
}

.commentContent {
  font-size: 14px;
  color: #666;
  margin-top: 5px;
}
