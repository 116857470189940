.payTypeSelectionEvent {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  margin-right: 20px;
  margin-top: 10px;
  flex-direction: column;
}

.payButtonsTop {
  display: flex;
  width: 50%;
  justify-content: space-between;
  align-items: center;
}
