.location-form {
  margin: 0;
  width: 100%;
}

.home-selection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 10px;
}

.title-add {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  gap: 5px;
  width: 100%;
  margin-bottom: 5px;
}

.home-extra {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.radio-row {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
  padding: 10px;
  margin: 0;
  overflow-x: auto;
  max-width: 100%;
}

.radio-row label {
  border: 1px solid #aaa;
  outline: none;
  width: 200px;
  height: 120px;
  border-radius: 10px;
  padding: 10px;
  flex-direction: row-reverse;
  margin: 0;
}

.radio-row label p {
  height: 100px;
  overflow: auto;
}

.radio-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}

.radio-info {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-shadow: 0px 4px 16px 8px rgba(48, 38, 62, 0.075);
  border-radius: 16px;
  padding: 10px;
  width: 100%;
}

.abs-site-link {
  position: fixed;
  bottom: 40px;
  left: 20px;
  color: hsla(0, 0%, 0%, 0.5);
  font-size: 16px;
}
.home-fee-text {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.home-fee-text-fee {
  border: #e0dee2 1px solid;
  border-radius: 35px;
  padding: 4px 8px;
  width: 85px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-left: auto;
}

.home-fee-text-fee span:last-child {
  color: black;
}

.mainDivAddress {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.mainDivAddressOne {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}

/* .address-capsule-list {
  display: flex;
  flex-direction: column;
  width: 95%;
  gap: 1rem;
}

.address-capsule {
  display: flex;
  background: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  align-items: center;
  justify-content: space-between;
  padding: 5px 8px;
  border-radius: 35px;
} */
.address-capsule-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  width: 95%;
  margin: 0 auto;
  margin-bottom: 1rem;
}

.address-capsule {
  display: flex;
  background: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  align-items: center;
  justify-content: space-between;
  padding: 5px 8px;
  border-radius: 35px;
}

.hiddenAddEyeCon {
  display: none;
}

.addressCapsule {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  margin: 5px 0;
  background-color: #f0f2f5;
  border-radius: 15px;
}

.addressCapsule span {
  margin-left: 10px;
}

.addressCapsule button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.raio-hidden > span:nth-child(1) {
  border: 1px solid red !important;
  display: none !important;
}
