.backBtn {
  position: absolute;
  top: 5%;
  left: 21%;
  z-index: 100;
  cursor: pointer;
  border-radius: 50%;
  border: none !important;
  height: 50px;
  width: 50px;
}
.exitBTN {
  position: absolute;
  top: 5%;
  right: 5%;
  z-index: 100;
  cursor: pointer;
  border-radius: 50%;
  border: none !important;
  height: 50px;
  width: 50px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}

.exitText {
  font-size: 16px;
  font-weight: 600;
  color: #30263e;
}

.exitBTN:hover {
  transform: scale(1.1);
  box-shadow: 0px 0px 10px 0px rgba(255, 0, 0, 0.5);
}

.exitBTNModalExit {
  background-color: red;
  border-radius: 35px !important;
  border: none !important;
  color: white;
  height: 30px;
  width: 100px;
  font-size: 16px;
  font-weight: 600;
  margin-right: 10px;
}

.exitBTNModalExit:hover {
  transform: scale(1.1);
  background-color: red !important;
  color: white !important;
  box-shadow: 0px 0px 10px 0px rgba(255, 0, 0, 0.5);
}

.exitBTNModalStay {
  background-color: #30263e;
  border-radius: 35px !important;
  border: none !important;
  color: white;
  height: 30px;
  width: 100px;
  font-size: 16px;
  font-weight: 600;
  margin-right: 10px;
}

.exitBTNModalStay:hover {
  transform: scale(1.1);
  background-color: blue !important;
  color: white !important;
  box-shadow: 0px 0px 10px 0px rgba(255, 0, 0, 0.5);
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  /* background-color: red; */
}

.loadingText {
  font-size: 20px;
  font-weight: 600;
}
