.global-input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.global-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.mainLeft {
  background-image: linear-gradient(
    159.61deg,
    rgba(130, 0, 255, 0.1),
    rgba(0, 176, 255, 0.1)
  );
  height: 100vh;
}

.mainRight {
  display: flex;
  flex-direction: column;
  /* margin-left: calc(30% + 20px); */
  overflow-y: auto;
  height: 100vh;
  width: 70%;
  /* border: 1px solid red; */
  align-items: center;
  width: 40%;
}
.selectorDiv .ant-form-item-label > label {
  font-weight: bold;
  font-size: 14px;
  /* border: 1px solid red; */
  margin-bottom: 5px;
}

/* Add this for vertical centering */
.option-flex-container {
  display: flex;
  align-items: center;
  /* border: 1px solid red; */
  width: 100%;
}
.CountrySelectorDiv {
  display: flex;
  background-color: #fff;
  border-radius: 35px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  align-items: center;
  width: 100%; /* Updated */
  height: 48px;
  padding-left: 0;
}

:where(.css-dev-only-do-not-override-1ck3jst).ant-input-borderless,
.ant-form-item-control-input-content,
.ant-col,
.ant-form-item-control,
.css-dev-only-do-not-override-1ck3jst {
  border: none !important;
}

.ant-input-affix-wrapper-focused {
  border: none !important;
  border-color: white !important;
}

.css-dev-only-do-not-override-1ck3jst {
  border: none !important;
}

.form-label-required {
  margin-bottom: 5px;
}
.formItemStyled {
  display: flex;
  background-color: #fff;
  border-radius: 35px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  align-items: center;
  width: 100%; /* Updated */
  height: 48px;
  padding-left: 15px;
}

.singleRadio {
  /* border: 1px solid red; */
}

.nextBTN {
  border-radius: 40px;
  background-color: #4e4dff;
  border: 1px solid #4e4dff;
  color: #fff;
  margin-top: 10px;
  cursor: pointer;
  font-weight: bold;
  width: 200px;
  height: 50px;
  font-size: large;
  margin-bottom: 50px;
}

.prevBTN {
  border-radius: 40px;
  background-color: #30263e;
  color: #fff;
  margin-top: 10px;
  cursor: pointer;
  font-weight: bold;
  width: 200px;
  height: 50px;
  font-size: large;
  margin-bottom: 50px;
  margin-right: 10px;
}
