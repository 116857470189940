.cardContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin-top: 7px;
  border-radius: 25px;
  /* margin-bottom: 15px; */
  background: linear-gradient(151deg, #ecd8ff 0%, #d8f3ff 60.52%) !important;
  min-width: 98%;
}

.profileSection {
  display: flex;
  align-items: center;
  gap: 10px;
}

.profileImage {
  width: 50px;
  height: 50px;
  background: #f2f2f2;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #8a8a8a;
}

.profileImage img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.profileName {
  font-size: 16px;
  color: #333;
}

.followButton {
  padding: 10px 20px;
  border: none;
  background-color: #ffffff;
  border-radius: 20px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-weight: bold;
}
.displayName {
  font-weight: bold;
  font-size: 16px;
}
