:global(.ant-tabs .ant-tabs-tab + .ant-tabs-tab) {
  margin: 0 0 0 15px !important;
  font-size: smaller;
}

:global(.ant-select-multiple .ant-select-selection-placeholder) {
  inset-inline-end: 0px;
  width : 100% !important;
}

:global(.ant-select-selector) {
  width : 100%;
}

:global(span.ant-select-selection-item ){
  align-items: center;
}
.customLayoutBG {
  background-color: #f5f4fa !important;
}

.parentContentdiv {
  display: flex;
  gap: 1rem;
}

.parentContentdiv_ {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.breadcrumbItem:hover {
  cursor: pointer;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.addButton {
  background-color: #4e4dff;
  color: white;
  border: none;
  border-radius: 35px;
  cursor: pointer;
}
.addButton:hover,
.addButton:active {
  background-color: #31263e !important;
  color: white !important;
  border: none;
  border-radius: 35px;
  margin-right: 10px;
  cursor: pointer;
}
.drawerDiv {
  border: 2px dashed #c7c5cb;
  background-color: #fafafa;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 95%;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.addButtonIcon {
  border-radius: 15px;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.addButtonIcon > img {
  height: 35px;
  width: 35px;
}

.addButtondrawer {
  background-color: #4e4dff;
  color: white;
  border: none;
  border-radius: 35px;
  margin-right: 10px;
  cursor: pointer;
  height: 35px;
  min-width: 90px;
  margin-top: 1rem;
  font-weight: bold;
  font-size: 16px;
}
.addButtondrawer:hover,
.addButtondrawer:active {
  background-color: #31263e !important;
  color: white !important;
  border: none;
  border-radius: 35px;
  margin-right: 10px;
  cursor: pointer;
}

.addARButton {
  background-color: transparent;
  color: black;
  border: 2px dashed #c7c5cb;
  border-radius: 10px;
  margin-right: 10px;
  cursor: pointer;
  height: 45px;
  min-width: 90px;
  margin-top: 1rem;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.leftDiv {
  width: 40%;
}

.rightDiv {
  width: 60%;
  display: flex;
  justify-content: center;
}

.rightDiv {
  background-color: white;
  border-radius: 15px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.formContainer {
  max-width: 600px;
  margin: auto;
}

.formItem {
  margin-bottom: 24px;
  width: 100%;
}

.formLabel {
  font-weight: 500;
  margin-bottom: 8px;
  display: inline-block;
}

.formInput,
.formSelect,
.formTextArea,
.formInput:hover,
.formInput:active,
.formInput:focus,
.formInput:visited,
.formSelect,
.formSelect:hover,
.formSelect:active,
.formSelect:focus,
.formSelect:visited,
.formTextArea,
.formTextArea:hover,
.formTextArea:active,
.formTextArea:focus,
.formTextArea:visited {
  width: 100%;
  background-color: white !important;
  border-radius: 35px !important;
  height: 40px !important;
  font-size: 14px !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  border: none !important;
  display: flex !important;
  align-items: center !important;
  /* justify-content: center !important; */
  padding: 0rem 1rem !important;
  border: 3px solid #f5f4f5 !important;
}

.formSelect_,
.formSelect_,
.formSelect_:hover,
.formSelect_:active,
.formSelect_:focus,
.formSelect_:visited {
  width: 100%;
  background-color: white !important;
  border-radius: 35px !important;
  height: 70px !important;
  font-size: 14px !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  border: none !important;
  display: flex !important;
  align-items: center !important;
  /* justify-content: center !important; */
  padding: 0rem 1rem !important;
  border: 3px solid #f5f4f5 !important;
  overflow-y: scroll;
}

.inlineFormItem {
  width: 100%;
}

.quantitySection {
  margin-top: 0.5rem;
  /* border-top: 1px solid #f0f0f0; */
}

.quantityRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.assignButton {
  margin-left: 8px;
}

/* .addButton {
  margin-top: 16px;
} */

.saveButtonContainer {
  text-align: right;
  margin-top: 24px;
}

.saveButton {
  width: 100%;
}

.checkboxContainer {
  margin-top: 16px;
}

.addColorButton {
  border: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
  color: #4e4dff;
  font-weight: bold;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
  border-radius: 35px;
}

.addColorButton:hover,
.addColorButton:active,
.addColorButton:focus,
.addColorButtonText:hover,
.addColorButtonText:active,
.addColorButtonText:focus {
  border: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
  color: #31263e;
  font-weight: bold;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
  border-radius: 35px;
}

.addColorButtonText {
  color: #4e4dff;
  font-weight: bold;
  font-size: 16px;
  text-decoration: underline;
}

.infoIcon {
  cursor: pointer;
  color: #4e4dff;
}

.colorPicker {
  border-radius: 35px !important;
}

.colorPicker > div {
  border-radius: 35px !important;
}

.specsTable {
  width: 100%;
  text-align: left;
}

.specsTable_ {
  width: 100%;
  text-align: left;
  max-height: 250px;
  overflow-y: scroll;
}

.specRow {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 10px;
  overflow-x: scroll;
  margin-left:10px;
}

.specCell {
  align-items: center;
  padding:2px
}

.colorCell {
  position: relative;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  overflow: hidden;
}

.colorSwatch {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  box-shadow: 0 0 0 1px #ddd;
}

.specRowHead {
  font-weight: bold;
  margin-left: 10px;
}

.form {
  margin: 2% !important;
  width: 100% !important;
}

.editButton,
.editButton:hover,
.editButton:active,
.editButton:focus {
  border: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
  color: #4e4dff;
  font-weight: bold;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
  border-radius: 35px;
}

.editButton:hover {
  transform: scale(1.2);
}

.actionCell {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  gap: 0.5rem !important;
}

/* New */

.orderContainer {
  background: #f5f4fa !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.serviceMainContainer {
  width: 90%;
  margin: 10px auto;
  margin-top: 110px;
}
.serviceHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.actionsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}
.actionsContainerNext {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.searchDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  border: none !important;
  background-color: white;
}

.calendarButton {
  border: 2px solid #cecbd4;
  min-width: 110px;
  border-radius: 35px;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  color: #30263e;
  background-color: transparent;
  background: transparent;
  font-weight: bold;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.calendarButton img {
  margin-right: 0.5rem;
}

.searchInput {
  border: none !important;
  outline: none !important;
  background-color: white;
  width: 100%;
  box-shadow: none !important;
}

.filterButton {
  color: black !important;
  background-color: white;
  border-radius: 20px;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  border: none !important;
}
.filterButton.active {
  background-color: #4e4dff;
  color: white !important;
  font-weight: bold;
}

.countingSpan {
  background: white;
  padding: 0.1rem 0.5rem;
  border-radius: 35px;
  color: #30263e;
  font-weight: bold;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin-left: 0.2rem;
}

.countingSpanParent{
  font-size: 12px !important;
}

.customTabs {
  margin: 0 0 0 20px !important;
}


.countingSpanInventory {
  background: white;
  padding: 0.2rem 0.8rem;
  border-radius: 8px;
  color: #4e4dff;
  font-weight: bold;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin-left: 0.2rem;
}

.Cartitem {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 1rem;
}

.separator {
  pointer-events: none !important;
  cursor: default !important;
}

.actionsContainerMain {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;

  margin-right: 1rem;
}

.myStoreText {
  font-size: 24px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0em;
}

.skuCountText {
  width: 32px;
  height: 24px;
  display: flex;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 8px 24px 0px #422f9b1a;
  font-size: 14px;
  font-weight: 700;
  color: #4e4dff;
  letter-spacing: -0.01em;
}

.tableClass {
  width: 100% !important;
}

.getcsvbutton {
  border: 1px solid #cecbd4;
  height: 40px;
  font-size: 14px !important;
  border-radius: 500px;
  background-color: transparent;
  background: transparent;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 1px solid #30263E !important;
  letter-spacing: 0.02em;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  padding: 0rem 1rem !important;
}

.csvOption{
  line-height: 0px;
  height:15px;
  width:15px; 
  margin-right:10px;
}

 
 