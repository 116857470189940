.goIconDiv {
  margin-top: -20px;
  background-color: white;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: 140px;
  cursor: pointer;
  box-shadow: 2px 2px 12px gray;
}

.goIconDiv svg path {
  color: black;
}

.uperBlocks {
  background: linear-gradient(45deg, rgb(229, 225, 251), rgb(221, 233, 250));
  width: 100%;
  height: 100px;
  border: none;
  border-radius: 8px;
  margin-left: 50px;
}

.cancel {
  background: #30263e;
  color: white;
  width: fit-content;
  font-weight: bold;
  border-radius: 20px;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  border: none !important;
}

.accept {
  background: #1677ff;
  color: white;
  width: fit-content;
  font-weight: bold;
  border-radius: 20px;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  border: none !important;
}

.rejected {
  background: #f6f6f6;
  color: rgb(247, 93, 93);
  width: fit-content;
  font-weight: bold;
  border-radius: 20px;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  box-shadow: 0px 8px 10px 0px rgba(48, 38, 62, 0.1);
  border: #f2f0ff;
}

.pending {
  background: #f6f6f6;
  color: #1677ff;
  font-weight: bold;
  width: fit-content;
  border-radius: 20px;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  box-shadow: 0px 8px 10px 0px rgba(48, 38, 62, 0.1);
  border: #f2f0ff;
}

.assigned {
  background: #f6f6f6;
  width: fit-content;
  color: limegreen;
  font-weight: bold;
  border-radius: 20px;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  box-shadow: 0px 8px 10px 0px rgba(48, 38, 62, 0.1);
  border: #f2f0ff;
}

.option {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 80px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  width: 165px;
  height: 100px;
  flex-shrink: 0;
}

.option:hover {
  transform: translateY(-15px);
  background-color: #ffffff;
}

.option::selection {
  transform: translateY(-15px);
  background-color: #f4f4f4;
}

.goIconDiv2 {
  background-color: white;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
}

.goIconDiv2 svg {
  font-size: 16px;
  margin: auto;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.goIconDiv2 svg path {
  color: black;
}

.optionIcons {
  background: linear-gradient(270deg, #f5f4fa 0%, rgba(245, 244, 250, 0) 100%);
  width: 680px;
  padding: 5px;
  border-radius: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
}

.optionIcons_nonprod {
  background: linear-gradient(270deg, #f5f4fa 0%, rgba(245, 244, 250, 0) 100%);
  width: 680px;
  padding: 5px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 30px;
}

.imageContainer {
  position: relative;
  display: inline-block;
  /* to display images in a line */
}

.imageContainer img {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  /* initially hide all images */
  transition: opacity 0.5s ease;
  /* for smooth transition */
}

.imageContainer img.active {
  opacity: 1;
  /* show active image */
}

.activeTab {
  border-bottom: 5px solid blue;
}

.drawerDiv {
  width: fit-content;
  background: linear-gradient(270deg, #f5f4fa 0%, rgba(245, 244, 250, 0) 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: none;
  padding: 10px;
  margin-left: 20px;
}

.buttonFilled {
  background-color: #4e4dff;
  color: white;
  font-weight: bold;
  border: none;
  border-radius: 35px;
  margin-right: 5px;
  cursor: pointer;
}
.acceptButton {
  background-color: #4e4dff;
  color: white;
  font-weight: bold;
  border: none;
  border-radius: 35px;
  margin-right: 20px;
  cursor: pointer;
}

.buttonFilled_ {
  background-color: #4e4dff;
  color: white;
  border: none;
  border-radius: 35px;
  width: 50%;
  cursor: pointer;
}

.buttonDark {
  color: white;
  font-weight: bold;
  background-color: #30263e;
  border-radius: 20px;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  border: none !important;
}

.NoDataFound {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 0px;
  height: 100%;
}

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.customLayoutConatinerTick {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.customLayoutContainerBox {
  margin: 20px auto;
  /* width: 60%; */
}

.customLayoutTitleText {
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.64px;
}

.customLayoutSmallText {
  margin-top: 15px;
  text-align: center;
  font-family: Google Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 0.28px;
  color: #606061;
}

.customLayoutClassNameProp {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  flex-direction: column;
}

.ant-modal-confirm-btns {
  text-align: center;
  display: flex;
  align-items: center;
  /* width: 100%; */
  justify-content: center;
}

.ant-modal-confirm-btns button {
  min-width: 20%;
}

.ant-modal-confirm-body.ant-modal-confirm-body-has-title>span {
  display: none;
}

.quantityButton {
  background: #f5f4fa;
  color: black;
  width: fit-content;
  font-weight: bold;
  border-radius: 20px;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  border: 1px solid rgb(206, 198, 198);
}

.buttonContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  width: 100%;
  margin-top: 1rem;
}

.buttonContainer Button:nth-child(2) {
  color: white;
  background-color: #31263e;
  height: 50px;
  width: 150px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 35px;
  box-shadow: 0px 10px 16px 0px rgba(48, 38, 62, 0.1);
}

.buttonContainer Button:first-child {
  color: white;
  background-color: #31263e;
  height: 50px;
  width: 150px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 35px;
  box-shadow: 0px 10px 16px 0px rgba(48, 38, 62, 0.1);
}

.buttonContainer Button:last-child {
  color: white;
  background-color: #4e4dff;
  height: 50px;
  width: 150px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 35px;
  box-shadow: 0px 10px 16px 0px rgba(48, 38, 62, 0.1);
}

.buttonContainer Button:last-child:hover,
.buttonContainer Button:last-child:active,
.buttonContainer Button:last-child:focus {
  border: #4e4dff 1px solid !important;
  color: #4e4dff !important;
  background-color: white !important;
}

.buttonContainer Button:first-child:hover,
.buttonContainer Button:first-child:active,
.buttonContainer Button:first-child:focus {
  border: #31263e 1px solid !important;
  color: #31263e !important;
  background-color: white !important;
}

.cartCount {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 30px;
  padding: 5px 10px;
  width: 136px;
  height: 50px;
}

.CartButton,
.CartButton2 {
  border: none;
  background: none;
  cursor: pointer;
  height: 40px !important;
  width: 40px !important;
  background-color: #f5f4f5 !important;
  border-radius: 50% !important;
  box-shadow: none !important;
}

.CartButton:hover,
.CartButton2:hover {
  border: none !important;
  background: none !important;
  cursor: pointer;
  height: 40px !important;
  width: 40px !important;
  background-color: #f5f4f5 !important;
  border-radius: 50% !important;
  box-shadow: none !important;
  outline: none !important;
}

.countDisplay {
  width: 40px;
  text-align: center;
  margin: 0 10px;
  line-height: 40px;
  font-size: 16px;
}

.earningBold {
  font-size: 20px;
  font-weight: 800;
}

.earningLightBold {
  font-size: 16px;
  font-weight: 500;
}

.contractDiv {
  border-radius: 10px;
  padding: 10px;
  margin-right: 10px;
  background: #f2f0ff;
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  height: 200px ;
  justify-content: space-around;
  margin-bottom: 10px;
}