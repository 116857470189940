.tableContainer {
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 24px;
  background-color: none !important;
  background: none !important;
  box-shadow: none !important;
  border: none !important;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.customHeader {
  background-color: white;
  margin-bottom: 24px;
  border-radius: 15px;
  padding-left: 15px;
  padding-right: 15px;
  width: 95%;
}

.highlightedRow {
  border: 2px solid grey !important;
  border-radius: 20px;
}

.tableBodyHeader {
  padding-top: 1rem;
}

.table {
  width: 100%;
  border-collapse: collapse !important;
  background-color: white !important;
}

.tableHeader {
  padding: 15px;

  height: 20px !important;
  border-radius: 5px !important;
}
.tableHeader tr,
.tableHeader th {
  height: 20px !important;
  border-radius: 5px !important;
  border: none !important;
}

.tableBody {
  background-color: #ffffff;
  padding: 15px;

  height: 20px !important;
  border-radius: 5px !important;
}

.tableBody tr td {
  border-bottom: 1px solid #ddd;
}

.tableBody tr:last-child td {
  border-bottom: none;
}

.iconButton {
  border: none;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
  cursor: pointer;
  opacity: 50%;
  font-size: 24px;
}

.collabButton {
  background-color: #ffffff;
  cursor: pointer;
  font-size: 24px;
  border: none;
  text-align: center;
}

.collabText {
  font-size: 16px;
}

.tbCell {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.actionDiv {
  display: flex;
  flex-direction: row;
  /* align-items: center;
    justify-content: center; */
}

.activeDiv {
  background: #f1f1ff;
  border-radius: 500px;
  width: 60px;
  color: #4e4dff;
  text-align: center;
}

.draftedDiv {
  background: #fff8eb;
  border-radius: 500px;
  color: #ff7a00;
  width: 68px;
  text-align: center;
}

.inactiveDiv {
  background: #fff2f2;
  border-radius: 500px;
  width: 70px;
  color: #ff5757;
  text-align: center;
}

.statusDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.productImage {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
}

.prodNameDiv {
  display: flex;
  justify-content: left;
  align-items: center;
}

.noCollabDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.lowStock {
  background: #fff2f2;
  border-radius: 40px;
  font-size: 10px;
  font-family: Google Sans;
  color: #ff5757;
  text-align: center;
  min-height: 20px;
  min-width: 36px;
  /* padding: 6.5px, 8px, 6.5px, 8px; */
  margin-left: 10px;
}

.soldOutText {
  color: #fd7e58;
}

.colorChip {
  display: flex;
  flex-direction: row;
  width: 100px;
}
.colorChip1 {
  display: flex;
  flex-direction: row;
  width: 200px;
}

.colorChip2 {
  display: flex;
  flex-direction: row;
  width: 30px;
}
.addButton {
  background-color: #4e4dff;
  color: white;
  border: none;
  border-radius: 35px;
  margin-right: 10px;
  cursor: pointer;
}
.addButton:hover,
.addButton:active {
  background-color: #31263e !important;
  color: white !important;
  border: none;
  border-radius: 35px;
  margin-right: 10px;
  cursor: pointer;
}

.selectedRow {
  background-color: #f2f2f2;
  display: flex;
  flex-direction: row;
}

.dropdownMain {
  background-color: #fff;
  border-radius: 35px;
  box-shadow: 0px 10px 30px 0px #30263e0f;
  height: 40px;
}


.formInput,
.formSelect,
.formTextArea,
.formInput:hover,
.formInput:active,
.formInput:focus,
.formInput:visited,
.formSelect,
.formSelect:hover,
.formSelect:active,
.formSelect:focus,
.formSelect:visited,
.formTextArea,
.formTextArea:hover,
.formTextArea:active,
.formTextArea:focus,
.formTextArea:visited {
  width: 100%;
  background-color: white !important;
  border-radius: 35px !important;
  height: 30px !important;
  font-size: 14px !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 8px !important;
  border: none !important;
  display: flex !important;
  align-items: center !important;
  /* justify-content: center !important; */
  padding: 0rem 1rem !important;
  border: 1px solid #f5f4f5 !important;
}

.formInput1 {
  width:90px;
  background-color: white !important;
  border-radius: 35px !important;
  height: 30px !important;
  font-size: 14px !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 8px !important;
  border: none !important;
  display: flex !important;
  align-items: center !important;
  /* justify-content: center !important; */
  padding: 0rem 1rem !important;
  border: 1px solid #f5f4f5 !important;

}

.productImage {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
  box-shadow: 0px 10px 30px 0px #30263e0f;
  border: 2px solid #d8d8d8;
}

.dgRowDiv {
  display: flex;
  align-items: center;
  font-family: Google Sans;
  font-size: 14px;
  font-weight: 500;
  padding: 3px;
}



.cartCount {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 40px;
  
  /* padding: 5px 10px; */
  /* width: 185px;
  height: 50px; */
}

.CartButton,
.CartButton2 {
  border: none;
  background: none;
  cursor: pointer;
  height: 20px !important;
  width: 20px !important;
  background-color: #f5f4f5 !important;
  border-radius: 50% !important;
  box-shadow: none !important;
  
  
}