.card {
  display: flex;
  width: 100px;
  height: 100px;
  padding: 20px;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  gap: 15px;
  margin: 4px;
  flex-shrink: 0;
  border-radius: 12px;
  background: #ffffff;
  box-shadow: 0px 5px 8px 0px rgba(48, 38, 62, 0.05);
}
