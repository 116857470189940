.servicesContainer {
  background: #f5f4fa !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.servicesContainer h2,
.servicesContainer h3,
.servicesContainer p {
  margin: 0;
}

.servicesContainer p {
  line-height: 1.25rem;
  word-spacing: 0.15rem;
  letter-spacing: 0.03rem;
}

.serviceMainContainer {
  width: 90%;
  margin: 10px auto;
  margin-top: 110px;
}

.serviceHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.actionsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.serviceInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}

.serviceActions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  padding: 0 20px;
  border-left: 1px solid #aaa;
}

.othersOfProvider {
  margin: 20px 0;
  padding: 20px 0;
  border-top: 1px solid #aaa;
  border-bottom: 1px solid #aaa;
}

.otherServices {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: auto;
  width: 100%;
  gap: 1rem;
  padding-top: 20px;
}

.otherServices::-webkit-scrollbar {
  display: none;
}

.boxInfoContainer {
  background: #fff;
  border-radius: 20px;
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  /* height: 100%; */
  width: 100%;
  /* border: 1px solid red; */
  align-items: center;
  justify-content: center;
}

.boxInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  padding: 0 20px;
  height: 100%;
  min-height: 90px;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.boxInfo p {
  color: #666;
}

.flexRow {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 40px;
}

.flexCol {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 20px;
}

.switchBox {
  flex-direction: row;
}

.borderBox {
  border-right: 1px solid #aaa;
  padding-bottom: auto;
}

.customProvider {
  background: linear-gradient(92.54deg, #8800ff, #00b2ff);
  border-radius: 25px;
  padding: 2px;
  padding-top: 1px;
}

.customProviderText {
  padding: 8px 15px;
  border-radius: 25px;
  background: #f5f4fa;
}

.customProvider p {
  background: linear-gradient(92.54deg, #8800ff, #00b2ff);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.addToCartButton {
  color: white;
  background-color: #31263e;
  height: 50px;
  width: 180px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 35px;
  margin-right: 15px;
  box-shadow: 0px 10px 16px 0px rgba(48, 38, 62, 0.1);
}
.bookNowButton {
  color: white;
  background-color: #4e4dff;
  height: 50px;
  width: 150px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 35px;
  box-shadow: 0px 10px 16px 0px rgba(48, 38, 62, 0.1);
}
.bookNowButton:hover,
.bookNowButton:active,
.bookNowButton:focus {
  border: #4e4dff 1px solid !important;
  color: #4e4dff !important;
  background-color: white !important;
}

.quoteButton {
  color: white;
  background-color: #4e4dff;
  height: 50px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 35px;
  box-shadow: 0px 10px 16px 0px rgba(48, 38, 62, 0.1);
}
.quoteButton:hover,
.quoteButton:active,
.quoteButton:focus {
  border: #4e4dff 1px solid !important;
  color: #4e4dff !important;
  background-color: white !important;
}

.addToCartButton:hover,
.addToCartButton:active,
.addToCartButton:focus {
  border: #31263e 1px solid !important;
  color: #31263e !important;
  background-color: white !important;
}

.addedToCart {
  border: #31263e 1px solid !important;
  color: #31263e !important;
  background-color: white !important;
  width: 200px !important;
  height: 50px;
  border-radius: 35px;
  margin-right: 15px;
}

.serviceDescription img {
  max-width: 100%;
  height: auto;
}

@media screen and (max-width: 900px) {
  .serviceMainContainer {
    margin-top: 1rem.5rem;
    margin-bottom: 15rem;
    display: flex !important;
  }
  .breadcrumbContainer {
    position: absolute;
    top: 100px;
    left: 0;
    width: 100%;
    z-index: 1;
    background-color: #f5f4fa;
    padding-left: 0.5rem;
  }
}

.requestQuote {
  font-size: 14px;
  font-weight: bold;
  margin-left: 10px;
  text-decoration: underline;
  /* box-shadow: 0px 10px 16px 0px rgba(48, 38, 62, 0.1); */
}

.infoIcon {
  margin-left: 2px;
}

.formItem {
  margin-bottom: 24px;
  background-color: transparent !important;
}

.formInput,
.formInput:hover,
.formInput:focus,
.formInput:active {
  width: 100%;
  background-color: white !important;
  border-radius: 20px !important;
  height: 100px !important;
  font-size: 14px !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  border: none !important;
  display: flex !important;
  align-items: center !important;
  /* justify-content: center !important; */
  padding: 0.2rem 1rem !important;
  border: 3px solid #f5f4f5 !important;
}

.submitButton {
  /* Styles for the submit button */
  background-color: #4e4dff;
  font-weight: bold;
  border-radius: 35px;
  height: 40px;
  min-width: 150px;
  font-size: 16px;
  border: none !important;
  color: #fff;
}

.submitButton:hover {
  background-color: #fff !important;
  color: #4e4dff;
  border-radius: 35px;
  height: 40px;
  min-width: 160px;
  font-size: 16px;
}

.rqForm {
  margin: 0% !important;
  padding: 0 !important;
}

.serviceProviderDetails {
  background-color: white;
  border-radius: 15px;
  padding: 20px;
  margin: 10px 0px;
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
  display: flex;
  align-items: flex-start;
  gap: 2rem;
  cursor: pointer;
  margin-top: 2rem;
}

.profilePicWrapper {
  flex-shrink: 0;
}

.profilePic {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
}

.serviceProviderInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.serviceProviderInfo h2 {
  margin: 0;
  font-size: 1.5rem;
  color: #31263e;
  font-weight: bold;
}

.bio {
  font-size: 1rem;
  color: #6c757d;
  margin: 5px 0;
}

.followers,
.collaborationStatus {
  font-size: 0.9rem;
  color: #495057;
  margin: 5px 0;
}

.boxInfoContainer {
  background: #fff;
  border-radius: 20px;
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  margin-top: 20px;
  width: 70%;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.boxInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  padding: 0 20px;
  height: 100%;
  min-height: 90px;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.boxInfo p {
  color: #666;
}

.collabBrand {
  font-weight: bold;
  font-size: 16px;
}
