.global-input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.global-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.mainLeft {
  background-image: linear-gradient(
    159.61deg,
    rgba(130, 0, 255, 0.1),
    rgba(0, 176, 255, 0.1)
  );
  height: 100vh;
}

.mainRight {
  display: flex;
  flex-direction: column;
  /* margin-left: calc(30% + 20px); */
  overflow-y: auto;
  height: 100vh;
  width: 70%;
  /* border: 1px solid red; */
  align-items: center;
  width: 40%;
}
.selectorDiv {
  padding-left: 0 !important;
  width: 90% !important;
}
.selectorDiv .ant-form-item-label > label {
  font-weight: bold;
  font-size: 14px;
  /* border: 1px solid red; */
  margin-bottom: 5px;
}

/* Add this for vertical centering */
.option-flex-container {
  display: flex;
  align-items: center;
  /* border: 1px solid red; */
  width: 100%;
}
.ant-form {
  /* background-color: #fff; */
}
.form-input {
  background: #fff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  /* border: 1px solid red; */
  display: flex;
  background-color: #fff;
  border-radius: 35px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  align-items: center;
  width: 100%; /* Updated */
  height: 48px;
  padding-left: 0;
}

:where(.css-dev-only-do-not-override-1ck3jst).ant-input-borderless,
.ant-form-item-control-input-content,
.ant-col,
.ant-form-item-control,
.css-dev-only-do-not-override-1ck3jst {
  border: none !important;
}

.ant-input-affix-wrapper-focused {
  border: none !important;
  border-color: white !important;
}

.css-dev-only-do-not-override-1ck3jst {
  border: none !important;
}

.form-label-required {
  margin-bottom: 5px;
}
.formItemStyled {
  display: flex;
  background-color: #fff;
  border-radius: 35px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  align-items: center;
  width: 100%; /* Updated */
  height: 48px;
  padding-left: 15px;
}

.singleRadio {
  /* border: 1px solid red; */
}

.nextBTN {
  border-radius: 40px;
  background-color: #4e4dff;
  border: 1px solid #4e4dff;
  color: #fff;
  margin-top: 10px;
  cursor: pointer;
  font-weight: bold;
  width: 200px;
  height: 50px;
  font-size: large;
  margin-bottom: 50px;
}

.prevBTN {
  border-radius: 40px;
  background-color: #30263e;
  color: #fff;
  margin-top: 10px;
  cursor: pointer;
  font-weight: bold;
  width: 200px;
  height: 50px;
  font-size: large;
  margin-bottom: 50px;
  margin-right: 10px;
}

.ant-select-selector,
.ant-select-open,
.ant-select-focused,
.option-flex-container,
.ant-select-selection-search,
.ant-select,
.ant-select-in-form-item {
  border: none !important;
}

:where(.css-dev-only-do-not-override-1ck3jst).ant-select-single {
  border: none !important;
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}

.ant-input-borderless:focus,
:where(.css-dev-only-do-not-override-1ck3jst).ant-input-borderless:hover {
  border: none !important;
}

.ant-input-affix-wrapper-focused {
  /* border: 5px solid red !important;  */
}
.ant-select-selector,
.ant-select-open,
.ant-select-focused {
  border: none !important;
  box-shadow: none !important;
}
.ant-input:focus,
.ant-input:hover,
.int-input:active {
  border: none !important;
  box-shadow: none !important; /* To remove any focus shadow */
  box-shadow: none !important;
}
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper:active {
  border: none !important;
  box-shadow: none !important; /* To remove any focus shadow */
}
.ant-form-item-control-input-content {
  border: none !important;
  box-shadow: none !important;
}
.css-dev-only-do-not-override-1ck3jst {
  border: none !important;
  box-shadow: none !important;
}
.ant-select {
  /* border: 1px solid red !important; */
}
/* .left-col,
.right-col {
  border: 1px solid red !important;
} */
