body {
  overflow-x: hidden;
}

.main {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: linear-gradient(
    159.61deg,
    rgba(130, 0, 255, 0.1),
    rgba(0, 176, 255, 0.1)
  );
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  overflow-y: auto;
  margin-bottom: 150px;
}

.child {
  /* border: 1px solid red; */
  border-radius: 35px 35px 0px 0px;
}

.child:first-child {
  flex: 2;
}

.child:last-child {
  flex: 8;

  background-color: #fff;
  display: flex;
  flex-direction: row;
  padding-top: 50px;
  padding-left: 80px;
}

.child:last-child .inner-div:first-child {
  flex: 7;

  background-color: white;
}

.child:last-child .inner-div:last-child {
  flex: 3;

  background-color: white;
}

.inner-div {
  display: flex;
  /* justify-content: left;
  align-items: center; */
  flex-direction: column;
  position: relative;
}

.image-text-container {
  text-align: center;
}

.image-text-container img {
  max-width: 100px;
}

.image-text-container h1 {
  margin: 0;
}

.image-text-container p {
  margin: 0;
}

.number-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  /* border: 1px solid red; */
}

.number-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  background: linear-gradient(
    159.61deg,
    rgba(130, 0, 255, 0.1),
    rgba(0, 176, 255, 0.1)
  );
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 70px;
  height: 70px;
  mix-blend-mode: normal;
  color: #4e4dff;
  padding-left: 10px;
  /* border: 1px solid red; */
}

.text-box {
  /* border: 1px solid red; */
  width: 80%;
}

.number-box h3 {
  font-weight: bold;
}

.last-div {
  text-align: center;
}

.gradient-card {
  border-radius: 20px;
  background: linear-gradient(
    182.06deg,
    rgba(130, 0, 255, 0.1) 0%,
    rgba(0, 176, 255, 0) 100%
  );
  width: 80%;
  height: 40%;
  margin-top: 50px;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  padding: 5px;
}

.list-item {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  background-color: white;
  margin-top: 50px;
  gap: 10px;
  width: 90%;
  font-size: 12px;
  padding: 15px;
  border-radius: 15px;
  height: 130px;
}
.list-item span {
  /* color: red; */
  margin-left: 10px;
}
.back-button {
  position: absolute;
  top: 0;
  right: 0;
  left: 70%;
  cursor: pointer;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0px 8px 24px rgba(66, 47, 155, 0.1);
  width: 44px;
  height: 44px;
  border: none;
}

.image-text-container {
  display: flex;
  align-items: center;
}

.image-text-container img {
  max-width: 100px;
}

.image-text-container .text-section {
  margin-left: 16px;
}

.image-text-container h1 {
  font-weight: bold;
  margin: 0;
}

.image-text-container p {
  margin: 0;
}

.number-container {
  display: flex;

  margin-top: 20px;
}

.number-box span {
  font-size: 24px;
  margin-right: 16px;
}

.number-box h3 {
  font-weight: bold;
  margin: 0;
}

.number-box p {
  margin: 0;
}

.last-div {
  /* border: 1px solid red; */
  margin-bottom: 50px;
  display: flex;
  justify-content: space-between;
  height: 60px;
}
