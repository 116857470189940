.MobInputBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 1rem;
  border-radius: 40px;
  border: 0.5px solid rgba(48, 38, 62, 0.05);
  box-shadow: 0px 5px 8px 0px rgba(48, 38, 62, 0.05);
  background: #fff;
}

.MobInputField {
  flex: 1;
  padding: 10px 12px;
  font-size: 1rem;
  outline: none;
  border: none;
  width: 100%;
}

.elevatedElement {
  outline: none;
  border: none;
  box-shadow: 0px 5px 8px 0px rgba(48, 38, 62, 0.05);
}

.iconBtn {
  background-color: #fff;
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  z-index: 1;
  box-shadow: 0px 5px 8px 0px rgba(48, 38, 62, 0.05);
}

@media screen and (max-width: 768px) {
}
