.select {
  border-radius: 35px !important;
  width: 90% !important;
}

.select .ant-select-selector {
  border-radius: 20px;
  border: none;
  box-shadow: none;
}

.FormInput {
  border: none !important;
  outline: none !important;
  width: 100%;
  box-shadow: none !important;
}

.DatepickerInput {
  border: none !important;
  outline: none !important;
  min-width: 90% !important;
}

.option {
  display: flex;
  align-items: center;
}

.option .ant-select-selector {
  display: flex;
  align-items: center;
}

.flagIcon {
  width: 20px;
  margin-right: 10px;
}
.selectInput {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 35px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}
.NormalInput {
  display: flex !important;
  background-color: #fff;
  border-radius: 35px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  align-items: center;
  height: 48px;
  padding-left: 5px;
}
.DateInput {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 35px;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 5px;
}
.innerDivlayout {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}
.divider {
  width: 100%;
}

.innerDiv {
  display: flex;
  gap: 2rem;
}

.select {
  border-radius: 35px !important;
  width: 90% !important;
}

.select .ant-select-selector {
  border-radius: 20px;
  border: none;
  box-shadow: none;
}

.FormInput {
  border: none !important;
  outline: none !important;
  width: 100%;
  box-shadow: none !important;
}

.FormInput:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.DatepickerInput {
  border: none !important;
  outline: none !important;
  min-width: 90% !important;
}

.option {
  display: flex;
  align-items: center;
}

.option .ant-select-selector {
  display: flex;
  align-items: center;
}

.flagIcon {
  width: 20px;
  margin-right: 10px;
}
.selectInput {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 35px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.NormalInput {
  display: flex !important;
  background-color: #fff;
  border-radius: 35px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  align-items: center;
  height: 48px;
  padding-left: 5px;
  padding-right: 5px;
}
.DateInput {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 35px;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 5px;
}
.innerDivlayout {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}
.divider {
  width: 100%;
}

.innerDiv {
  display: flex;
}

/* /////////////////////////// */

.phoneNumberInput {
  display: flex !important;
  align-items: center !important;
  padding: 0rem 1rem !important;
}
.selectCountryCode {
  display: flex !important;
  align-items: center !important;
}

.selectCountryCode > div > span {
  display: flex !important;
  align-items: center !important;
  padding-right: 8px;
}

.select > div > span {
  display: flex !important;
  align-items: center !important;
  padding-right: 8px;
}

.dividerInput {
  border: 1px solid #e8e8e8 !important;
  width: 2px;
  height: 20px;
  background-color: #e8e8e8;
}

.submitButton {
  background: #4e4dff;
  color: white;
  width: 95%;
  height: 50px;
  border-radius: 35px;
  border: none;
  margin-top: 20px;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
}

.mailIcon {
  margin-left: 1rem;
  font-size: 18px;
}

.multinumber {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
}

.numberRemove {
  border-radius: 50%;
  padding: 2px;
  margin-left: 5px;
  cursor: pointer;
  margin-top: 1rem;
  box-shadow: rgba(100, 100, 111, 0.2);
}

.numberRadio {
  margin-top: 1rem;
}

.numberAdd {
  display: inline-flex;
  padding: 19.5px 25px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 40px;
  border: 2px solid rgba(48, 38, 62, 0.25);
  font-weight: bold;
  width: 50%;
}

.continue {
  border-radius: 40px;
  background: var(--Blue, #4e4dff);
  color: white;
  width: 50%;
  height: 50px;
  border: none;
  margin-top: 20px;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 2rem;
}

.innerTermsDiv {
  margin: 0;
}

.terms {
  font-weight: bold;
  margin: 0;
  margin-left: 50px;
}
.inputval {
  background: #f7f6f7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  width: 100%;
  font-weight: bold;
  font-size: 16px;
  border-radius: 40px;
  max-height: 40px;
}
.radioSelect {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.disabled {
  pointer-events: none;
  background-color: #f4f4f5;
}

.phoneNumbersContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  /* border: 1px solid red; */
  width: 100%;
}

.phoneNumberBlock,
.representativeNumberBlock {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  /* border: 1px solid red; */
  min-width: 50%;
}

.numberActions {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 1rem;
  margin-top: 1rem;
}

.numberRemove {
  border-radius: 50%;
  padding: 2px;
  margin-left: 5px;
  cursor: pointer;
  margin-top: 2rem;
  box-shadow: rgba(100, 100, 111, 0.2);
}

.uploadText {
  font-size: 14px;
}

.asterisk {
  color: red;
}
