.input .ant-select-dropdown,
.ant-space-horizontal {
  border: 1px solid red !important;
}

.formInput,
.formSelect,
.formTextArea,
.formInput:hover,
.formInput:active,
.formInput:focus,
.formInput:visited,
.formSelect,
.formSelect:hover,
.formSelect:active,
.formSelect:focus,
.formSelect:visited,
.formTextArea,
.formTextArea:hover,
.formTextArea:active,
.formTextArea:focus,
.formTextArea:visited {
  width: 100%;
  background-color: white !important;
  border-radius: 35px !important;
  height: 40px !important;
  font-size: 14px !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  border: none !important;
  display: flex !important;
  align-items: center !important;
  /* justify-content: center !important; */
  padding: 0rem 1rem !important;
  border: 3px solid #f5f4f5 !important;
}

.addButton {
  background-color: #4e4dff;
  color: white;
  border: none;
  border-radius: 35px;
  margin-right: 10px;
  cursor: pointer;
}
.addButton:hover,
.addButton:active {
  background-color: #31263e !important;
  color: white !important;
  border: none;
  border-radius: 35px;
  margin-right: 10px;
  cursor: pointer;
}
