.calendarContainer {
  background: #f5f4fa !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.crumbLink {
  font-size: 16px;
  color: gray;
  text-decoration: none;
}

.crumbLink:hover,
.crumbLink:active {
  color: #30263e;
}

.activeLink {
  font-size: 16px;
  text-decoration: none;
  color: #30263e;
  margin: 0;
}

.calendarContainer td {
  background: transparent !important;
}

.calendarMainContainer th {
  background: transparent !important;
  padding: 10px 0 !important;
  font-size: 20px !important;
  overflow: hidden;
  display: none !important;
}

.calendarMainContainer h2 {
  font-size: 24px !important;
  margin: 0;
}

.calendarMainContainer {
  width: 90%;
  margin: 10px auto;
  margin-top: 110px;
}

.calendarMainContainer col,
.calendarMainContainer colgroup,
.calendarMainContainer tbody,
.calendarMainContainer table,
.calendarMainContainer tr td:first-child {
  border: none !important;
}

.calendarMainContainer tr td:nth-child(2) {
  border: none;
  border-bottom: 1px solid #ccc !important;
}

.calendarHeader {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
  gap: 20px;
}

.cancelContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.holidaysContainer {
  background: #fff;
  border-radius: 20px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  padding: 20px;
}

.holidaysContainer h2 {
  margin-right: 0 !important;
  font-size: 18px !important;
}

.holidayHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.holidayHeader h2 {
  margin: 0;
}

.holidayCards {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  width: calc(100% - 40px);
  margin: 0 20px;
}

.holidayCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  padding: 20px;
  position: relative;
  box-shadow: 0 0 10px 5px rgba(48, 38, 62, 0.075) !important;
  width: 100%;
  border-radius: 20px;
}

.grayText {
  color: #999;
  font-weight: 500;
}

.holidayDetails {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
}

.holidayInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
}

.topLeftAction {
  position: relative;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.holidayIcon {
  padding: 10px;
  border-radius: 10px;
  background: linear-gradient(92.54deg, #8800ff, #00b2ff);
  display: flex;
  align-items: center;
  justify-content: center;
}

.selectorcontainerCal {
  padding: 5px 2px;
}
.selectorcontainerCal select {
  border: none;
  outline: none;
  background: transparent !important;
  background-color: transparent !important;
  font-size: 18px;
  font-weight: bold;
}
