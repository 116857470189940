.eventcartcardcontainer {
  border-radius: 18px;
  background: var(--White, #fff);
  box-shadow: 0px 10px 30px 0px rgba(48, 38, 62, 0.06);
  width: 340px;
  height: 135px;
  display: flex;
  justify-content: space-around;
  padding: 12px;
}
.eventcartcardcontent {
  display: flex;
  padding: 13px;
}
.eventcartcarddetails {
  margin-top: 0px;
  margin-left: 5px;
}
.eventbuttoncontainer {
  margin-top: 36px;
  margin-left: 7px;
}
.eventcartcardinformation {
  margin: 0;
  padding: 0;
}
.deletedcartcard {
  position: relative;
  bottom: 10px;
  left: 77px;
  background-color: black;
  color: white;
  border-radius: 15px;
  margin-right: 5px;
  border: none;
  padding: 6px;
  cursor: pointer;
}
.checkoutcartbutton {
  position: relative;
  bottom: 38px;
  left: 135px;
  background: var(--Blue, #4e4dff);
  color: white;
  border-radius: 15px;
  margin-right: 5px;
  border: none;
  padding: 6px;
  cursor: pointer;
}
.quantitybuttoncontainer {
  margin-top: 30px;
}
.quantityButton {
}

/* CardStyles.module.css */
.cardContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  margin-bottom: 10px;
  background-color: white;
  border-radius: 8px;
  cursor: pointer;
  width: 80%;
}

.cardDetails {
  display: flex;
  flex-direction: column;
}

.cardName {
  font-weight: bold;
  margin-bottom: 5px;
  margin-left: 5px;
}

.cardInfo {
  display: flex;
  align-items: center;
  font-size: 0.9em;
  color: #555;
}

.cardBrand,
.cardNumber {
  margin-left: 5px;
  margin-right: 5px;
}

/* .radioCircle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #ccc;
    margin-right: 1rem;
  } */
.radioCircle {
  border: 2px solid #ccc;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 1rem;
  background: white;
  position: relative;
}

.radioCircle.selectedCard {
  border: 0px solid #ccc;
  width: 20px;
  height: 20px;
}
/* Add styles for the + Add Credit Card button if needed */
.addButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-top: 10px;
  background-color: #f0f0f0;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
}

.cardLoadMore {
  border: none;
  outline: none;
  background: none;
  background-color: none;
  color: #4e4dff;
  font-weight: bold;
  cursor: pointer;
}
