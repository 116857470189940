.carouselWrapper {
  position: relative;
  width: 100%;
  border-radius: 25px !important;
}

.imageWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  width: 100%;
  height: 620px;
  object-fit: cover;
  border-radius: 25px;
  transition: transform 0.3s ease;
  cursor: zoom-in;
}

.previewImage {
  /* border: 1px solid red !important; */
  display: none !important;
}

.imageOverlay {
  position: absolute;
  bottom: 15%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 1;
  padding: 0 20px;
}

.carouselWrapper:hover .imageOverlay {
  opacity: 1;
}

.navButton {
  background: rgba(255, 255, 255, 0.25);
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  transition: background 0.3s ease;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(0px);
  -webkit-backdrop-filter: blur(0px);
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.navButton:hover {
  background: rgba(255, 255, 255, 0.5);
}

.svgIcon {
  width: 30px;
  height: 30px;
  font-weight: bold;
  stroke-width: 2.5px;
}

.thumbnailWrapper {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  position: relative;
}

.thumbnailContainer {
  margin: 0 5px;
  cursor: pointer;
  position: relative;
  width: 50px;
  height: 50px;
  transition: transform 0.3s ease, top 0.3s ease;
}

.thumbnailGrayscale {
  filter: grayscale(100%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  transition: filter 0.3s ease, border 0.3s ease;
}

.thumbnailSelected {
  filter: none;
  width: 90%;
  height: 90%;
  object-fit: cover;
  border: 2px solid #8800ff;
  border-radius: 5px;
  transition: transform 0.3s ease, filter 0.3s ease, top 0.3s ease;
  top: -10px;
  transform: translateY(-10%);
}

.thumbnailGradient {
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  height: 6px;
  background: linear-gradient(to right, #8800ff, #00b2ff);
  border-radius: 10px;
}

.expandButton {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
  top: 10px;
  right: 10px;
  background: rgba(255, 255, 255, 0.25);
  padding: 10px;
  border-radius: 35px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.expandButton > p {
  margin: 0;
  padding: 0;
  font-weight: bold;
  color: #30263e;
  font-size: 16px;
}

.expandIcon {
  width: 24px;
  height: 24px;
  font-weight: bold;
  stroke-width: 2.5px;
}

.expandButton:hover {
  background: rgba(255, 255, 255, 0.5);
}

.fullImageModal {
  max-width: 100%;
  margin: 0 auto;
  background-color: transparent;
  padding: 0;
  box-shadow: none;
}

.fullImage {
  width: 100%;
  height: auto;
  border-radius: 15px;
  object-fit: cover;
}

@media (max-width: 768px) {
  .image {
    height: 300px;
  }

  .thumbnailContainer {
    width: 40px;
    height: 40px;
  }

  .fullImageModal {
    max-width: 768px;
    margin: 0 auto;
    background-color: transparent;
    padding: 0;
    box-shadow: none;
  }

  .fullImage {
    width: 100%;
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .image {
    height: 200px;
  }

  .thumbnailContainer {
    width: 30px;
    height: 30px;
  }

  .fullImageModal {
    max-width: auto;
    margin: 0 auto !important;
    background-color: transparent;
    padding: 0 !important;
    box-shadow: none;
  }

  .fullImage {
    width: 300px;
    height: auto;
    object-fit: contain;
  }
}
