.FormInput,
.FormInput:focus,
.FormInput:active,
.FormInput:hover {
  border: none !important;
  outline: none !important;
  width: 100%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
  border-radius: 50px !important;
  padding: 10px !important;
  font-size: 16px !important;
  gap: 10px !important;
  min-height: 40px !important;
  max-height: 50px !important;
}

.FormInput > input,
.FormInput:focus > input,
.FormInput:active > input,
.FormInput:hover > input {
  padding-left: 10px !important;
}

.FormInputNumber,
.FormInputNumber:focus,
.FormInputNumber:active,
.FormInputNumber:hover {
  border: none !important;
  outline: none !important;
  border-radius: 50px !important;
}

.imageContainer {
  margin-bottom: 16px;
}

.imageWrapper {
  position: relative;
  margin: 10px;
  max-width: 300px;
}

.image {
  width: 100%;
  display: block;
  border-radius: 15px;
  max-width: 300px;
  max-height: 300px;
  min-height: 300px;
  object-fit: cover;
  min-width: 300px;
}

.imageTitle {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  display: block;
  background-color: white;
  border: 2px dotted gray;
  color: black;
  padding: 5px;
  border-radius: 15px;
}

.removeButton {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  display: block;
  background-color: white;
  border: none;
  color: red;
  padding: 5px;
  border-radius: 50%;
}

.closeBtn {
  border: 2px solid #d9d9d9 !important;
}

.closeBtn:hover {
  background-color: #d9d9d9 !important;
  transform: scale(1.1);
}

.removeButton:hover {
  background-color: rgba(255, 0, 0, 0.2) !important;
}

.numberAdd {
  display: inline-flex;
  padding: 19.5px 25px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 40px;
  border: 2px solid rgba(48, 38, 62, 0.25);
  font-weight: bold;
  width: 20%;
  margin-top: 2rem;
}
