/* .custom-date-picker {
  display: flex;
  align-items: center;
  user-select: none;
  position: relative;
  padding: 10px;
  background-color: #f0f0f7;
  background: linear-gradient(151deg, #ecd8ff, #d8f3ff 60.52%) !important;
  border-radius: 20px;
  height: 50px;
  border-radius: 35px;
  border: 2px solid white;
  justify-content: space-between;
} */
.custom-date-picker {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  /* padding: 12px 5px; */
  background-color: #f0f0f7;
  background: linear-gradient(151deg, #ecd8ff, #d8f3ff 60.52%);
  border-radius: 60px;
  height: 75px;
  border: 2px solid white;
  border-radius: 35px;
  margin-bottom: 2rem;
}

.nav-button {
  cursor: pointer;
  background: none;
  border: none;
  padding: 0 10px;
  font-size: 18px;
  background-color: white;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  padding: 0;
  cursor: pointer;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.nav-button.left {
  left: -8px;
}

.nav-button.right {
  right: -8px;
}

.days-container {
  display: flex;
  align-items: center;
  position: relative;
  font-size: 18px;
  font-weight: bold;
  width: 95%;
}

.day {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40px;
  cursor: pointer;
  padding: 5px;
  color: #636479;
}

.not-current-month {
  color: #d3d3d3;
}

.day-of-week {
  font-size: 12px;
  margin-bottom: 0.3rem;
}

.day-of-month {
  font-size: 16px;
}

.month-label {
  font-size: 12px;
  padding: 5px;
  /* margin-left: -20px; */
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  white-space: nowrap;
  color: #636479;
  font-weight: bold;
}

.month-label.vertical {
  position: absolute;
  left: 50%;
  transform: translateX(-50%) rotate(180deg);
}

.selectedDate {
  background: rgba(78, 77, 255, 1) !important;
  color: white;
  border-radius: 40px;
  /* padding: 10px 5px; */
  height: 60px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* .sunday {
  color: green;
} */
