.servicesContainer {
  background: #f5f4fa !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.contentDiv {
  margin-top: 10rem;
  width: 80%;
  height: 80vh;
}

.smallMainBox{
  display:flex;
  flex-direction: column;
  gap:"5px";
  width: max-content;
  height:80px;
  background-color: white;
  border-radius: 10px;
  border: none;
  align-items: center;
}
.rightDiv{
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap:10px;
  background-color: white;
  border-radius: 10px;
  justify-content: center;
  
}