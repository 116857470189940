.cardCategory {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  border-radius: 30px;
  background-color: #fff;
  position: absolute;
  bottom: -15px;
  left: 15px;
  padding: 8px 15px;
}

.cardRating {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  background-color: #30263e;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 15px;
  padding-left: 10px;
}

.cardActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 10px 15px;
  padding-left: 10px;
  gap: 5px;
}

.productCard {
  width: 100%;
  height: 100px;
  text-decoration: none;
  color: gray;
  position: relative;
  gap: 20px;
}

.serviceInfo {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 7px;
}

.cardPricing {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  justify-content: space-between;
  width: 100%;
}

.priceDetails {
  display: flex;
  flex-direction: row;
}

.priceVal {
  color: #30263e;
  font-weight: 500;
  margin: 0;
}

.image-gallery-image {
  width: 100% !important;
  height: 100% !important;
  object-fit: none;
  /* border-radius: 20px !important; */
}

.countOnIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(92.54deg, #8800ff, #00b2ff);
  color: #fff;
  font-weight: bold;
  z-index: 2;
  width: 14px;
  height: 14px;
  font-size: 10px;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 6px;
}

.buyButton {
  background-color: #4e4dff;
  color: white;
  border-radius: 35px;
  width: 88px;
  height: 35px;
  border: 0;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
}

.serviceInfo-blur {
  filter: blur(5px);
}

/* on hover shake animation */
.addToCartIcon:hover {
  transform: scale(1.2);
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.buyButton:hover {
  background-color: #30263e;
}

.discountedPricing{
  display: flex;
  flex-direction: column;
  align-items: center;
}