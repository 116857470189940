.businessCard {
  width: 100%;
}

.form {
  margin: auto !important;
  margin-top: 0 !important;
  min-width: 100% !important;
}

.container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  gap: 50px;
  margin-top: 5rem;
}

.personalInfoSection {
  width: 50%;
  /* background-color: #fff; */
  padding: 20px;
  border-radius: 8px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.name {
  font-size: 20px;
  margin-top: 10px;
}

.contactInfo {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.email,
.phone {
  width: 48%;
}

.editButton,
.deleteButton {
  background-color: white !important;
  border: none !important;
}

.editButton {
  margin-left: auto;
  border-radius: 50% !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
  height: 40px !important;
  width: 40px !important;
}

.deleteButton {
  border-radius: 35px !important;
  margin-top: 1rem !important;
  color: red !important;
  font-weight: bold;
  font: 1em sans-serif;
  height: 40px !important;
}

.profileSection {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: 300px;
  background: linear-gradient(180deg, #e5e1fb 0, #dde9fa 50%, #fff);
  border-radius: 8px;
}
.adminAvatar {
  height: 200px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border-radius: 50%;
  background-color: #f0f0f0;
}
.avatarName {
  font-size: 56px;
  font-weight: 500;
  text-transform: capitalize;
}

.verifiedIcon {
  margin-left: 10px;
  vertical-align: middle;
}

.label {
  font-weight: bold;
  color: #928d9c;
}

.emailAddress,
.phoneNumber {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
}

.divider {
  width: 11px;
  height: 100%;
  background-color: #928d9c;
  margin: 0 10px;
}

.loginInput {
  border: 2px solid #f5f4f5 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  border-radius: 35px !important;
  padding: 5px !important;
  font-size: 16px !important;
  width: 100% !important;
  margin-bottom: 10px !important;
  min-height: 45px !important;
  color: #30263e !important;
}

.loginInput:hover {
  border: 2px solid #f5f4f5 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  border-radius: 35px !important;
  padding: 5px !important;
  font-size: 16px !important;
  width: 100% !important;
  margin-bottom: 10px !important;
  min-height: 45px !important;
  color: #30263e !important;
}

.loginInputDisable {
  border: none !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  box-shadow: none !important;
  border-radius: 35px !important;
  padding: 5px !important;
  font-size: 16px !important;
  width: 100% !important;
  margin-bottom: 10px !important;
  min-height: 45px !important;
  color: #30263e !important;
}

.loginInput > span {
  /* border: 1px solid red !important; */
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
  color: #30263e !important;
}

.loginInput > input {
}

.dateInput {
  border: 2px solid #f5f4f5 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  border-radius: 35px !important;
  padding: 5px !important;
  font-size: 16px !important;
  width: 100% !important;
  margin-bottom: 10px !important;
  min-height: 45px !important;
  color: #30263e !important;
}

.dateInput > div {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.FormInput,
.FormInput:focus,
.FormInput:active,
.FormInput:hover {
  border: none !important;
  outline: none !important;
  width: 100%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
  border-radius: 50px !important;
  padding: 10px !important;
  font-size: 16px !important;
  gap: 10px !important;
  min-height: 40px !important;
  max-height: 50px !important;
  background-color: white !important;
}

.FormInput > input,
.FormInput:focus > input,
.FormInput:active > input,
.FormInput:hover > input {
  padding-left: 10px !important;
}

.option {
  border: none !important;
}

.FormNumberInputEdit,
.FormNumberInputEdit:focus,
.FormNumberInputEdit:active,
.FormNumberInputEdit:hover {
  border: none !important;
  outline: none !important;
  width: 100%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
  border-radius: 50px !important;
  padding: 10px !important;
  font-size: 16px !important;
  gap: 10px !important;
  min-height: 40px !important;
  max-height: 50px !important;
  background-color: white !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-around !important;
}

.FormNumberInputEdit > input,
.FormNumberInputEdit:focus > input,
.FormNumberInputEdit:active > input,
.FormNumberInputEdit:hover > input {
  padding-left: 10px !important;
}

.FormNumberInputEditDisable {
  border: none !important;
  outline: none !important;
  width: 100%;
  box-shadow: none !important;
  border-radius: 50px !important;
  padding: 10px !important;
  font-size: 16px !important;
  gap: 10px !important;
  min-height: 40px !important;
  max-height: 50px !important;
  background-color: white !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-around !important;
}

.primaryButton {
  border: 1px solid #30263e;
  border-radius: 50px;
  padding: 5px 15px;
  font-size: 15px;
}

.FormInputNumber,
.FormInputNumber:focus,
.FormInputNumber:active,
.FormInputNumber:hover {
  border: none !important;
  outline: none !important;
  border-radius: 50px !important;
}

.FormInputNumberDisable {
  border: none !important;
  outline: none !important;
  border-radius: 50px !important;
}

.loginButton {
  background-color: #4e4dff !important;
  color: white !important;
  border-radius: 50px !important;
  padding: 10px 20px !important;
  font-size: 16px !important;
  width: 30% !important;
  margin-bottom: 10px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 10px !important;
  border: none !important;
  outline: none !important;
  min-height: 50px !important;
  font-size: large !important;
  margin-top: 1rem !important;
}

.loginButton:hover {
  background-color: #30263e !important;
}

.successModal {
  /* border: 1px solid red !important; */
}

.successModal .ant-modal-content {
  /* border: 1px solid red !important; */
}

.successModal .ant-modal-content {
  border-radius: 8px;
}

.modalContent {
  text-align: center;
  /* border: 1px solid red; */
  font-weight: bold;
}

.textContent {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.FormRemoveButton {
  all: unset;
}

.FormRemoveButton {
  border-radius: 50% !important;
  padding: 5px !important;
  font-size: 15px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #30263e !important;
  cursor: pointer !important;
  height: 20px !important;
  width: 20px !important;
}

.FormRemoveButton:hover {
  color: white !important;
  background-color: #e62903 !important;
  content: "Remove";
}

.FormInputNumberItem {
  gap: 1rem !important;
}

.FormInputNumberItemDisable {
  gap: 0rem !important;
  border: none !important;
}

.FormAddNumberButton {
  all: unset;
  border-radius: 35px !important;
  padding: 5px !important;
  font-size: 15px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: white !important;
  cursor: pointer !important;
  height: 20px !important;
  width: 100% !important;
  margin-top: 5px !important;
  background-color: #30263e !important;
}

.FormAddNumberButton:hover {
  color: white !important;
  background-color: #3e2e26 !important;
}

.saveBtn {
  background-color: #30263e !important;
  color: white !important;
  border-radius: 50px !important;
  padding: 0px 20px !important;
  font-size: 12px !important;
  width: 100% !important;
  margin-bottom: 10px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 10px !important;
  border: none !important;
  outline: none !important;
  min-height: 30px !important;
  font-size: large !important;
}

.saveBtn:hover {
  background-color: #4e4dff !important;
}

.customUpload {
  display: flex !important;
  /* flex-direction: column; */
  align-items: center !important;
  flex-wrap: wrap !important;
  border: 2px solid #f5f4f5 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  border-radius: 15px;
  padding: 1rem;
}

.customUpload .ant-upload-list,
.customUpload .ant-upload-wrapper .ant-upload-list {
  border: 1px solid red !important;
}

.uploadContainer {
  display: flex !important;
  flex-direction: column !important;
  /* align-items: center !important; */
  justify-content: center !important;
  padding: 1rem !important;
  gap: 1rem !important;
  border-radius: 15px !important;
  border: 2px solid #f5f4f5 !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
}
