.MobiledashboardNavBarContainer {
  display: none;
  border-bottom: 1px solid #ccc;

  width: 100%;
  justify-content: space-around;
  flex-direction: column-reverse;
}

.MobilecountOnIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(92.54deg, #8800ff, #00b2ff);
  color: #fff;
  font-weight: bold;
  z-index: 2;
  width: 18px;
  height: 18px;
  font-size: 12px;
  border-radius: 50%;
  position: absolute;
  top: 12px;
  right: 6px;
}

@media screen and (max-width: 900px) {
  .MobilenavbarRow {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .MobilerightNav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .MobilenavLinks {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex: 1;
  }

  .MobilenavLink {
    font-size: 18px;
    color: gray;
  }

  .MobilenavLink:hover,
  .MobilenavLink:active {
    color: #30263e;
  }

  .MobileactiveLink {
    color: #30263e;
    font-weight: bold;
  }

  .MobilenavIcons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
  }

  .MobilecountOnIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(92Mobile54deg, #8800ff, #00b2ff);
    color: #fff;
    font-weight: bold;
    z-index: 2;
    width: 18px;
    height: 18px;
    font-size: 12px;
    border-radius: 50%;
    position: absolute;
    top: 12px;
    right: 6px;
  }

  .Mobilehamburger {
    display: none;
  }

  .MobiledashboardNavBarContainer {
    display: flex;
    background: linear-gradient(
      135deg,
      rgba(235, 220, 255, 1) 10%,
      rgba(235, 220, 255, 0) 50%,
      rgba(217, 243, 255, 0) 50%,
      rgba(217, 243, 255, 1) 100%
    );
    height: 12vh;
    padding-bottom: 2rem;
    border: none;
  }
}

@media screen and (max-width: 520px) {
  .MobilenavbarRow {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
  }
}
