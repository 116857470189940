.topDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f5f4fa;
  position: sticky;
  top: 0;
  z-index: 10;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  margin-top: 0;
  padding-left: 15px;
  padding-right: 15px;
  /* border-radius: 8px; */
}

html {
  scroll-behavior: smooth;
}

.dashboardMainDiv {
  height: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
}
.searchDiv,
.topSearchDiv {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 10;
  background-color: white;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 35px;
  height: 45px;
  width: 300px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.searchInput {
  background-color: transparent;
  height: 80%;
  border: none;
  margin-right: 5px;
  width: 100%;
  outline: none;
  border-radius: 35px;
}
.filterIcon {
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  cursor: pointer;
}
.SearchIcon {
  margin-left: 5px;
}
.topCardsInfo {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 15px;
  margin-top: 1rem;
}

.topCardsInfo .card {
  border-radius: 10px;
  background: linear-gradient(
    97deg,
    #f0e6ff 0.26%,
    #ccf3ff 74.94%,
    #ccf3ff 89.32%
  );
  height: 90px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 20px;
}
.cardTop {
  color: #30263e;
  leading-trim: both;
  text-edge: cap;
  font-family: Google Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.cardBottom {
  color: #675f77;
  leading-trim: both;
  text-edge: cap;
  font-family: Google Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.42px;
  margin-top: 0.2rem;
}

.contentDiv {
  height: 100vh;
  width: 100%;
  margin-top: 1rem;
  display: flex;
  gap: 2%;
  flex: 1;
}

.mainContainerContent {
  height: calc(100vh - [Height of your topDiv]);
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
}
.leftdiv {
  width: 60%;
  height: 100vh;
}
.rightdiv {
  width: 38%;
  height: 100vh;
}
.tableContainer {
  box-shadow: none;
  border: 1px solid #e0e0e0;
}

.table {
  min-width: 650;
}

.searchField {
  margin-bottom: 20px;
  border-radius: 35px;
  background: #fff;
}

.showLess {
  cursor: pointer;
  color: #1976d2;
  margin-right: 10px;
}

.viewMore {
  cursor: pointer;
  color: #1976d2;
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.tableFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #dddddd;
  width: 100%;
}

.viewControls {
  display: flex;
}

.showLess {
  margin-right: 10px;
  cursor: pointer;
  color: #1976d2;
  border: none;
  background-color: white;
  background: white;
  color: #4e4dff;
  font-weight: bold;
  text-decoration: underline;
}

.viewMore {
  cursor: pointer;
  color: #1976d2;
  border: none;
  background-color: white;
  background: white;
  color: #4e4dff;
  font-weight: bold;
  text-decoration: underline;
}

.tableContainer {
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 24px;
  background-color: none !important;
  background: none !important;
  box-shadow: none !important;
  border: none !important;
}

.customHeader {
  background-color: white;
  margin-bottom: 24px;
  border-radius: 15px;
  padding-left: 15px;
  padding-right: 15px;
}

.table {
  width: 100%;
  border-collapse: collapse;
  background-color: white !important;
}

.tableHeader {
  padding: 15px;

  height: 20px !important;
  border-radius: 5px !important;
}
.tableHeader tr,
.tableHeader th {
  height: 20px !important;
  border-radius: 5px !important;
  border: none !important;
}

.tableBody {
  background-color: #ffffff;
  padding: 15px;

  height: 20px !important;
  border-radius: 5px !important;
}

.tableBody tr td {
  border-bottom: 1px solid #ddd;
  /* border: 1px solid red; */
}

.tableBody tr:last-child td {
  border-bottom: none;
}

.viewMore,
.showLess {
}

.pagination {
}

.productImage {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
}

.rating div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border-radius: 20px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.05) 0%,
      rgba(255, 255, 255, 0.05) 100%
    ),
    rgba(48, 38, 62, 0.9);
  backdrop-filter: blur(10px);
  color: white;
  /* width: 50px; */
  font-weight: bold;
  padding-left: 1rem;
  padding-right: 1rem;
}
.rating {
  display: flex;
  align-items: center;
  justify-content: center;
}

.commonDiv {
display: flex;
align-items:center;
justify-content:center;
}
