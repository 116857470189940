.fc-timegrid-slots tr {
  height: 150px !important;
  overflow: hidden;
}

.fc .fc-toolbar {
  flex-wrap: wrap;
  gap: 10px;
}

td.fc .fc-timegrid-slot {
  padding: 0 !important;
}

.fc .fc-toolbar.fc-header-toolbar button,
.fc .fc-toolbar.fc-header-toolbar button:active,
.fc .fc-toolbar.fc-header-toolbar button:hover {
  color: transparent !important;
  border: none;
  outline: none;
  cursor: pointer;
  background: rgb(78, 77, 255);
  font-weight: 500;
  text-transform: capitalize;
}

.fc .fc-view-harness,
.fc .fc-scrollgrid-liquid {
  background-color: transparent !important;
}

.fc-timegrid-slot .fc-timegrid-slot-lane,
.fc .fc-timegrid-slot {
  border-bottom: #e1dfe7 !important;
}

.fc-scroller {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}

.fc-scroller::-webkit-scrollbar {
  display: none !important;
}

.fc-event {
  background-color: transparent !important;
  border: none !important;
  width: 100% !important;
}

.fc-event-main {
  /* border: 2px solid red !important; */
}

.fc-v-event .fc-event-main {
  color: none !important;
}

.EventCard_address__KIAr0 {
  color: #31263e !important;
}

.fc-timegrid-event {
  box-shadow: none !important;
}
