.customLayoutConatinerTick {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.customLayoutContainerBox {
  margin: 20px auto;
  /* width: 60%; */
}

.customLayoutTitleText {
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.64px;
}
.customLayoutSmallText {
  margin-top: 15px;
  text-align: center;
  font-family: Google Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.28px;
  color: #606061;
}

.customLayoutClassNameProp {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  flex-direction: column;
}

.ant-modal-confirm-btns {
  text-align: center;
  display: flex;
  align-items: center;
  /* width: 100%; */
  justify-content: center;
}
.ant-modal-confirm-btns button {
  min-width: 20%;
}

.ant-modal-confirm-body.ant-modal-confirm-body-has-title > span {
  display: none;
}

.customLayoutButtonPopup {
  background-color: #4e4dff !important;
  color: white !important;
  border-radius: 20px !important;
  font-size: 16px !important;
  font-weight: bold !important;
}

.customLayoutButtonPopup:hover,
.customLayoutButtonPopup:active,
.customLayoutButtonPopup:focus,
.customLayoutButtonPopup:visited {
  background-color: #30263e !important;
  color: white !important;
  border-radius: 20px !important;
  font-size: 16px !important;
  font-weight: bold !important;
}
