@import url("https://fonts.cdnfonts.com/css/google-sans");

* {
  font-family: Google Sans;
}


.customLayoutBG {
  background-color: #f5f4fa !important;
}

.searchDiv,
.topSearchDiv {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 10;
  background-color: white;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 500px;
  height: 56px;
  width: 340px;
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
}

.searchInput {
  background-color: transparent;
  width: 100%;
  height: 56px;
  border: none;
  border-radius: 500px;
  color: #7E8B9A;
  font-weight: 700;
  font-size: 16px;
}

.skuCountText {
  width: 32px;
  height: 24px;
  display: flex;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 8px 24px 0px #422F9B1A;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  color: #4E4DFF;
  letter-spacing: -0.01em;
}

.myStoreText {
  font-size: 24px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0em;
  padding-left: 10px;
}



.filterIcon {
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  cursor: pointer;
}

.SearchIcon {
  margin-left: 5px;
}

.addButton {
  background-color: #4e4dff;
  color: white;
  border: none;
  font-weight: 700;
  height: 56px;
  width: 189px;
  border-radius: 500px;
  cursor: pointer;
}

.getcsvbutton {
  border: 1px solid #cecbd4;
  min-width: 140px;
  height: 56px;
  border-radius: 500px;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  color: #30263E;
  background-color: transparent;
  background: transparent;
  font-weight: bold;
  display: flex;
  justify-content: space-around;
  align-items: center;

  font-size: 16px;
  color: #30263E;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.02em;
}

.numberbox {
  position: absolute;
  background-color: white;
  color: #4e4dff;
  font-family: Google Sans;
  font-weight: 700;
  font-size: 14px;
  box-shadow: 0px 8px 24px 0px rgba(66, 47, 155, 0.1);
  width: 32px;
  height: 24px;
  top: 132px;
  left: 421px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.countOnIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(92.54deg, #8800ff, #00b2ff);
  color: #fff;
  font-weight: bold;
  z-index: 2;
  width: 18px;
  height: 18px;
  font-size: 12px;
  border-radius: 50%;
  position: absolute;
  top: 12px;
  right: 6px;
}

.iconsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.hiddenScrollbar {
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
}

.customLayoutBackground,
.ant-layout,
:where(.css-dev-only-do-not-override-1adbn6x).ant-layout,
.ant-layout,
.css-dev-only-do-not-override-1adbn6x {
  background-color: #f5f4fa !important;
  background: #f5f4fa !important;
}

:where(.css-dev-only-do-not-override-1adbn6x).ant-layout {
  background: #f5f4fa !important;
}

.userMenu {
  transform: rotate(90deg);
}