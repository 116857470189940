.myDocumentsOuterConatiner {
  width: 80%;
}

.heading {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  font-weight: bold;
  margin-top: 30px;
}

.label {
  font-size: 18px;
  width: 100%;
  display: flex;
  align-items: start;
  color: grey;
  justify-content: start;
  margin: 20px 0px;
}
.myDocumentsAnswerConatiner {
  justify-content: start;
  width: 100%;
  font-size: 22px;
  display: flex;
  align-items: center;
  margin: 20px 0px;
}
.bottomBorder {
  padding-bottom: 50px;
  border-bottom: 0.2px grey solid;
}
.mainDiv {
  height: 250px;
  width: 300px;
  background-color: white;
}

.img {
  height: 200px;
  width: 280px;
  padding-left: 10px;
  padding-top: 5px;
  padding-right: 5px;
  /* border: 6px white solid; */
}
.smallDiv {
  display: flex;
  justify-content: space-between;
  height: 50px;
  width: 280px;
  padding-left: 20px;
}

.smallImage {
  width: 30px;
  height: 30px;
  padding-bottom: -80px;
  padding-right: 20px;
}
.documentEditBtnContainer {
  width: 50px;
  background: #fff;
  border-radius: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}
.verifyDocumentsContainer {
  height: 60vh;
  padding: 20px;
  /* margin-top: 80px; */
  margin: auto;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  /* overflow-y: scroll; */
}

.verifyDocumentsContainerForm {
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  /* justify-content: center; */
}

.verifyDocumentsContainerForm > input {
  width: 100%;
}

.skipBtn {
  position: absolute;
  top: 30px;
  right: 50px;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}
