.registerContainer {
  width: 100%;
  height: "90%";
  /* overflow-y: auto; */
  display: flex;
  justify-content: space-around;
  /* align-items: center; */
  flex-direction: column;
  flex-grow: 1;
  /* padding: 10px 50px; */
}

.registerFormMainContainer {
  padding-bottom: 50px;
  margin: 3%;
  margin-left: 8%;
}

.extraButtonSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.registerInputBGWhite {
  background-color: #fff !important;
}
