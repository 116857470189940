.serviceListing {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  grid-gap: 2rem;
  margin-top: 10px;
  margin-bottom: 20px;
}
.servicesContainer {
  background: #f5f4fa !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.serviceMainContainer {
  width: 90%;
  margin: 10px auto;
  margin-top: 110px;
}
.backColour {
  background: #f5f4fa !important ;
}

.breadcrumbContainer {
  background: #fff;
  width: 100%;
  margin: -20px 40px 2px -70px;
  z-index: 1000;
  position: fixed;
  min-width: auto;
  /* margin-right: 20px; */
  padding: 0px 0px 0px 100px;
}
.backBtnContainer {
  cursor: pointer;
  height: 30px;
  display: inline-block;
  position: fixed;
  z-index: 1000;
  align-items: start;
}
.backBtnContainer {
  cursor: pointer;
  height: 30px;
  display: inline-block;
  position: fixed;
  z-index: 1000;
  align-items: start;
}
.breadcrumbContainer {
  padding-left: 90px;
  background: #fff;
  width: 100%;
  margin: -20px -60px -70px -100px;
  z-index: 1000;
  position: fixed;
  min-width: auto;
  padding-left: 140px;
}

.profileDiv {
  display: block;
  padding-top: 20px;
  height: 200px;
  width: 200px;
  align-items: center;
  justify-content: center;
  background: #efe2f2;
  border-radius: 20px;
}
.nameDiv {
  font-size: large;
  margin-top: 20px;
  display: flex;
  padding-left: 0px;
  align-items: center;
  background: #efe2f2;
  justify-content: center;
  color: black;
  font-weight: 800;
}
.cardCategory {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  border-radius: 30px;
  background-color: #fff;
  position: absolute;
  bottom: -15px;
  left: 15px;
  padding: 8px 15px;
}

.cardRating {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  background-color: #30263e;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 15px;
  padding-left: 10px;
}

.cardActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 10px 15px;
  padding-left: 10px;
  gap: 5px;
}

.productCard {
  width: 100%;
  text-decoration: none;
  color: gray;
  min-width: 200px;
  max-width: 400px;
  position: relative;
  gap: 10px;
}

.serviceInfo {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 7px;
}

.cardPricing {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.priceVal {
  color: #30263e;
  font-weight: 500;
  margin: 0;
}

.image-gallery-image {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
  /* border-radius: 20px !important; */
}

.countOnIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(92.54deg, #8800ff, #00b2ff);
  color: #fff;
  font-weight: bold;
  z-index: 2;
  width: 14px;
  height: 14px;
  font-size: 10px;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 6px;
}

.buyButton {
  background-color: #4e4dff;
  color: white;
  border-radius: 35px;
  width: 88px;
  height: 35px;
  border: 0;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
}

.serviceInfo-blur {
  filter: blur(5px);
}

/* on hover shake animation */
.addToCartIcon:hover {
  transform: scale(1.2);
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.buyButton:hover {
  background-color: #30263e;
}
