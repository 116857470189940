.dashboardNavBarContainer {
  display: flex;
  align-items: center;
  width: 85%;
  margin: auto;
  height: 50px;
  padding: 10px 100%;
  margin-bottom: 20px;
  justify-content: center;

  position: fixed;
  z-index: 10;
  background-color: #f5f4fa;
  border-radius: 8px;
  border: 2px solid #e1dfe7;
  flex-direction: column;
  /* position: relative; */
}

.showBanner {
  margin-top: 33px !important;
  margin-bottom: 32px !important;
}

.dashboardNavBarContainer.open {
  /* height: 80px; */
}

.navbarRow {
  width: 98vw !important;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rightNav {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  margin-left: 0.1rem;
}

.navLinks {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex: 1;
}

.navLink {
  font-size: 17px;
  color: gray;
}

.navLink:hover,
.navLink:active {
  color: #30263e;
}

.activeLink {
  color: #30263e;
  font-weight: 700;
}

.navIcons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

.countOnIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(92.54deg, #8800ff, #00b2ff);
  color: #fff;
  font-weight: bold;
  z-index: 2;
  width: 18px;
  height: 18px;
  font-size: 12px;
  border-radius: 50%;
  position: absolute;
  top: 6px;
  right: 6px;
}

.newNotification {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: red;
  color: white;
  font-weight: bold;
  z-index: 2;
  width: 30px;
  height: 18px;
  font-size: 10px;
  border-radius: 10px;
  position: absolute;
  top: 6px;
  right: 6px;
  animation: blink 1s infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.hamburger {
  display: none;
}
.dashboardNavBarContainerBottom {
  display: none;
  align-items: center;
  width: 90%;
  margin: auto;
  height: 60px;
  padding: 5px 0;
  margin-bottom: 20px;
  justify-content: center;
  border-bottom: 1px solid #ccc;
  position: fixed;
  z-index: 10;
  background-color: #f5f4fa;
}

@media screen and (max-width: 900px) {
  body {
    background-color: white !important;
  }
  .dashboardNavBarContainer {
    display: none;
  }
  .dashboardNavBarContainerBottom {
    display: flex;
    bottom: 0;
    margin-bottom: 0;
    width: 100%;
  }
  .selectedLink {
    font-weight: bold;
    color: #000;
  }
  /* 
  .selectedImage {
    transform: scale(1.2);
    filter: grayscale(0) !important;
  }

  .unSelected {
    filter: grayscale(100%) !important;
  } */
}

.filterOptions {
  position: absolute;
  top: 110%;
  transform: translateX(-50%);
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 20;
  height: 50px;
  width: auto;
  border-radius: 35px;
}
