.topCardContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  width: 95px;
  cursor: pointer;
}

.topImage {
  object-fit: cover;
  width: 95px;
  height: 95px;
  border-radius: 20px;
}

.ratingContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 2px solid #fff;
  border-radius: 25px;
  background-color: #30263e;
  position: absolute;
  bottom: -15px;
  left: 20px;
  padding: 3px 10px;
  padding-left: 8px;
}

.ratingText {
  color: #fff;
  margin: 0;
  font-size: 0.8rem;
}

.topInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  cursor: pointer;
}

.m-0 {
  margin: 0;
}

.mainTitle {
  font-size: 1rem;
  color: #30263e;
  font-weight: 500;
  margin: 0;
  text-align: center;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.subTitle {
  margin: 0;
  color: gray;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.extraText {
  font-size: 0.7rem;
  margin: 0;
  color: gray;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.multiLine {
  white-space: wrap;
}
