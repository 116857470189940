/* .profileContainer {
  background: #f5f4fa !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
} */
.container {
  background: #f5f4fa !important;
  display: flex;
  /* gap: 20px; */
  /* padding: 20px; */
  max-width: 100%;
  margin: 0px auto;
  height: 100vh;
  /* overflow-y: auto; */
  margin-bottom: 40px;
  /* align-items: center; */
  width: 100%;
  /* overflow-y: auto; */
  /* box-sizing: border-box; */
  /* overflow-y: auto; */
  /* border: 1px solid red; */
  justify-content: space-between;
  /* align-items: center; */
  /* border: 1px solid red; */
  margin-bottom: 5rem;
}

.servicesContainer {
  background: #f5f4fa !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.serviceMainContainer {
  width: 85%;
  margin: 10px auto;
  margin-top: 110px;
}

.customProfileLayoufycugtContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.customProfileLayoutContainerDashboard {
  margin: auto;
}

.continueReading {
  font-size: 18px;
  font-weight: 500;
  text-decoration: underline;
}

.sidebar {
  display: flex;
  flex-direction: column;
  /* gap: 20px; */
  /* margin-top: 5%; */
  align-items: center;
  /* border: 1px solid red; */
  width: auto;
  height: 100vh;
}
.profileContentOuterBox {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 20px;
  flex-direction: column;
  margin: 20px;
  height: 65vh;
  border:"3px solid grey";
}
.sectionTitle {
  font-size: 30px;
  margin-bottom: 10px;
  color: #333;
}

/* .accountSection, */
.accountSection {
  /* border: 1px solid orange; */
  display: flex;
  justify-content: center;
  /* align-items: center; */
  /* margin-top: 20px; */
  flex-direction: column;
  width: auto;
}

.settingsSection {
  /* border: 1px solid orange; */
  display: flex;
  justify-content: center;
  /* align-items: center; */
  /* margin-top: 20px; */
  flex-direction: column;
  width: auto;
}
.accountOptions {
  display: grid;
  grid-template-columns: repeat(4, 185px);
  gap: 20px;
  /* padding: 20px; */
  border: none;
  margin-top: 20px;
  /* border: 1px solid green; */
  width: auto;
  align-items: center;
  justify-content: center;
}

.settingsOptions {
  display: grid;
  grid-template-columns: repeat(4, 185px);
  gap: 20px;
  margin-bottom: 50px;
  /* padding: 20px; */
  border: none;
  margin-top: 20px;
  /* border: 1px solid green; */
  width: auto;
  align-items: center;
  justify-content: center;
}

.option {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 80px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  width: 165px;
  height: 100px;
  flex-shrink: 0;
}

.option:hover {
  transform: translateY(-15px);
  background-color: #ffffff;
}
.option::selection {
  transform: translateY(-15px);
  background-color: #f4f4f4;
}

.optionIcons {
  background: linear-gradient(270deg, #f5f4fa 0%, rgba(245, 244, 250, 0) 100%);
  width: 145px;
  padding: 5px;
  border-radius: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
}
.iconDiv {
  background-color: #f5f4fa;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.goIconDiv {
  background-color: white;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.goIconDiv svg {
  font-size: 16px;
  margin: auto;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.goIconDiv svg path {
  color: black;
}

.option svg {
  margin-right: 8px;
}

.profileContent {
  margin-top:70px;
  display: flex;
  flex-direction: column;
  padding: 0.8rem;
  border-radius: 20px;
  width: 100%;
  background-color: #fff;
}

.profileHeader {
  padding-top: .5rem;
  padding-bottom: .5rem;
  width: 100%;
  border-radius: 20px;
  align-items: center;
  background: linear-gradient(to bottom, rgb(229, 225, 251) 0%, rgb(221, 233, 250) 50%, white 100%);
  display: flex;
  flex-direction: column;
  height: fit-content;
  justify-content: center;
  text-align: center;
  /* border : 2px solid pink */
}

.profileImage {
  width: 90px;
  height: 90px;
  border-radius: 75px;
  object-fit: cover;
}

.profileImageWrapper {
  position: relative;
  width: 90px;
  height: 90px;
  border-radius: 75px;
  /* margin-bottom: 5px; */
  border : 2px solid #fff
}

.editIconWrapper {
  position: relative;
  top: -30px; 
  right: -30px; 
  background: rgba(0, 0, 0, 0.5);
 opacity: 80%;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.editIcon {
  color: #fff;
  font-size: 0.9rem; 
}

.profileName {
  margin: 0;
  color: #333;
  font-size: 15px;
  margin : 2px
}

.profileDescription {
  color: #666;
  font-size: 12px;
  padding: 10px;
}

.myProfileContainer {
  /* margin-top: 50px; */
  display: flex;
}

.backBtnContainer {
  cursor: pointer;
  display: inline-block;
  position: fixed;
  z-index: 1000;
  margin-bottom: 10px;
  align-items: start;
  height: 40px;
  width: 40px;
  border-radius: 80%;
  margin-top: 5px;
  margin-bottom: 5px;
}

.breadcrumbContainer {
  background: #fff;
  width: 2400px;
  margin: -20px auto 2px -200px;
  align-items: center;
  display: flex;
  min-width: auto;
  padding: 0px 10px;
  height: 30px;
  padding: 0px 0px 0px 200px;
}

@media (max-width: 1200px) {
  .accountOptions,
  .settingsOptions {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
}

.partition {
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .container {
    grid-template-columns: 1fr;
  }


  .profileImage {
    margin: 0 auto;
  }

  .accountOptions,
  .settingsOptions {
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  }
}
/* .Collaboration {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  margin-top: 5px;
  background: rgba(238, 230, 255, 255);
  border-radius: 5px;
  padding: 0px;
  font-weight: bold;
} */

.collaboration {
  margin-top: 20px;
  width: 102%;
  border-radius: 20px;
  align-items: center;
  background: #fff;
  display: flex;
  height: 35px;
  justify-content: space-between;
  padding: 0.6rem;
}

.innerBox {
  display:flex;
  justify-content: center;
  align-items: center;
}

.NoDataFound {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 0px;
  height: 100%;
}

.iconButtonArrow {
    box-shadow: 0px 8px 24px 0px #422f9b1a;
    margin-left: 40px;
    color: #30263e;
    background-color: #fff;
    margin-top: 10px;
    margin-bottom: 0;
}

.iconButtonContainer {
  display:flex;
  margin-left:20px;
  height:40px;
  width:40px;
  border-radius:50%;
  background:white;
  margin-top:10px;
}
