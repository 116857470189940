/* Left column with gradient background */
.left-col {
  /* position: fixed;  */
  top: 0; /* Add this line */
  left: 0; /* Add this line */
  width: 50%;
  background: linear-gradient(
    159.61deg,
    rgba(130, 0, 255, 0.1),
    rgba(0, 176, 255, 0.1)
  );
  height: 100vh;
  /* border: 1px solid red; */
}

/* Right column with white background */

/* Steps with rounded corners */
.ant-steps-item {
  border-radius: 35px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  width: 50%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

/* Selected step with gradient background */
.ant-steps-item-process .ant-steps-item-container {
  background: linear-gradient(92.54deg, #8800ff, #00b2ff);
  /* border: 2px solid gray; */
  color: #fff;
  border-radius: 35px;
  display: flex;
  align-items: center;
  height: 60px;
}

/* Unselected steps with white background */
.ant-steps-item-wait .ant-steps-item-container,
.ant-steps-item-finish .ant-steps-item-container {
  background: #fff;
  /* border: 1px solid red; */
  border-radius: 35px;
  color: #fff;
  display: flex;
  align-items: center;
  height: 60px;
  /* border: 10px solid red; */
}

/* Hide the stepper line */
.ant-steps-item .ant-steps-item-tail::before {
  visibility: hidden;
}

.ant-steps-item-tail::after {
  visibility: hidden;
}

.ant-steps {
  margin-left: 20%;
  margin-top: 20%;
}

.ant-steps-item .ant-steps-item-process,
.ant-steps-item-active {
  /* border: 10px solid red; */
  display: flex;
  align-items: center; /* Vertical centering */
  justify-content: center; /* Horizontal centering */
}

/* ... existing styles ... */

/* Add margin-left to the step icon */
.ant-steps-item-icon {
  margin-left: 10px !important;
  height: 40px !important;
  width: 40px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  justify-items: center;
  /* border: 1px solid red !important; */
}
.ant-steps-icon {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  justify-items: center;
  /* border: 1px solid red !important; */
}
/* Center content inside of step item container vertically */
.ant-steps-item-container {
  display: flex !important;
  align-items: center;
  /* border: 1px solid red !important; */
}

/* Center content inside of the selected step item container */
.ant-steps-item-process .ant-steps-item-container {
  display: flex;
  align-items: center; /* Vertical centering */
}

.ant-steps-item-content {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.ant-steps-item-title {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.ant-steps-item-active .ant-steps-item-title {
  color: white !important;
  font-weight: bold !important;
}
.ant-steps-item-active .ant-steps-item-icon {
  background-color: white !important;
  color: black !important;
  border: none !important;
  /* text-align: center !important; */
}

.ant-steps-item-wait .ant-steps-item-icon {
  box-shadow: 0px 8px 24px rgba(66, 47, 155, 0.1) !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
  color: white !important;
  background-color: transparent !important;
  /* border: 1px solid red !important; */
}

.ant-steps-item-finish .ant-steps-item-icon {
  background: linear-gradient(92.54deg, #8800ff, #00b2ff) !important;
}

.ant-steps-item-finish .ant-steps-icon {
  color: white !important;
  font-size: medium !important;
}
.ant-steps-item-finish .ant-steps-item-title {
  font-weight: bold !important;
  font-size: large !important;
}

/* Form Styles */
/* Custom CSS for form inputs */
form {
  /* border: 1px solid red; */
  /* margin-top: 200%; */
  height: auto;
  width: 90%;
}
.ant-form {
  background-color: transparent;
  margin: 10% auto;
}
.form-input {
  background: #fff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

/* Custom CSS for form labels */
.form-label {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 5px;
  margin-left: 5px;
}
.form-label1 {
  margin-left: 15px;
}
/* Custom CSS for required asterisk */
.form-label-required::after {
  content: " *";
  color: red;
}

.selectorDiv {
  /* border: 1px solid red; */
  background-color: #fff;
  border-radius: 35px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  align-items: center;
  /* justify-content: center; */
  width: 40%;
  height: 48px;
  padding-left: 15px;
}

.countryIMG {
  /* border: 1px solid red; */
  margin-left: 5px;
  /* margin-right: 15px; */
  /* transform: scale(1.2); */
  filter: brightness(1.05);
}
.selectForm {
  margin-left: 20px;
  background-color: transparent;
  border: none !important;
  width: 90%;
}
.ant-select-selector,
.ant-select-open,
.ant-select-focused {
  border: none !important;
}

.left-col-form,
.right-col-form {
  /* border: 1px solid red; */
  width: 50vh;
  /* height: 500px; */
  display: flex !important;
  flex-direction: column;
  /* justify-content: space-around; */
  padding: 5px;
}

.basicInput {
  display: flex !important;
  background-color: #fff;
  border-radius: 35px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 48px;
  margin-top: 5px;
  /* border: 1px solid red; */
}
.inputformItem {
  width: 90%;
}

.buttonNXT {
  border-radius: 40px;
  background-color: #4e4dff;

  border: 1px solid #4e4dff;
  color: #fff;
  margin-top: 10px;
  cursor: pointer;
  font-weight: bold;
  width: 200px;
  height: 50px;
  font-size: large;
  margin-bottom: 50px;
}
.buttonPRV {
  border-radius: 40px;
  background-color: #30263e;
  color: #fff;
  margin-top: 10px;
  cursor: pointer;
  font-weight: bold;
  width: 200px;
  height: 50px;
  font-size: large;
  margin-bottom: 50px;
  margin-right: 10px;
}

.ant-input-disabled {
  background-color: rgba(48, 38, 62, 0.05) !important;
  border-radius: 35px !important;
  width: 65% !important;
}

.primaryphoneadd {
  border: 1px solid rgba(48, 38, 62, 0.25) !important;
  background-color: black;
}
/* ackground-color: rgba(48, 38, 62, 0.05); */
/* Left column with gradient background */
.left-col {
  /* position: fixed;  */
  top: 0; /* Add this line */
  left: 0; /* Add this line */
  width: 50%;
  background: linear-gradient(
    159.61deg,
    rgba(130, 0, 255, 0.1),
    rgba(0, 176, 255, 0.1)
  );
  height: 100vh;
  /* border: 1px solid red; */
}

/* Right column with white background */
.right-col {
  border: 1px solid red;
  background: #fff;
  height: 100%;
  display: flex;
  height: 100vh;
  /* align-items: center; */
  /* justify-content: center; */
  left: 0;
  padding-top: 20px;
  padding-left: 1vw;
  overflow-y: auto;
}

/* Steps with rounded corners */
.ant-steps-item {
  border-radius: 35px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  width: 50%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

/* Selected step with gradient background */
.ant-steps-item-process .ant-steps-item-container {
  background: linear-gradient(92.54deg, #8800ff, #00b2ff);
  /* border: 2px solid gray; */
  color: #fff;
  border-radius: 35px;
  display: flex;
  align-items: center;
  height: 60px;
}

/* Unselected steps with white background */
.ant-steps-item-wait .ant-steps-item-container,
.ant-steps-item-finish .ant-steps-item-container {
  background: #fff;
  /* border: 1px solid red; */
  border-radius: 35px;
  color: #fff;
  display: flex;
  align-items: center;
  height: 60px;
  /* border: 10px solid red; */
}

/* Hide the stepper line */
.ant-steps-item .ant-steps-item-tail::before {
  visibility: hidden;
}

.ant-steps-item-tail::after {
  visibility: hidden;
}

.ant-steps {
  margin-left: 20%;
  margin-top: 20%;
}

.ant-steps-item .ant-steps-item-process,
.ant-steps-item-active {
  /* border: 10px solid red; */
  display: flex;
  align-items: center; /* Vertical centering */
  justify-content: center; /* Horizontal centering */
}

/* ... existing styles ... */

/* Add margin-left to the step icon */
.ant-steps-item-icon {
  margin-left: 10px !important;
  height: 40px !important;
  width: 40px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  justify-items: center;
  /* border: 1px solid red !important; */
}
.ant-steps-icon {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  justify-items: center;
  /* border: 1px solid red !important; */
}
/* Center content inside of step item container vertically */
.ant-steps-item-container {
  display: flex !important;
  align-items: center;
  /* border: 1px solid red !important; */
}

/* Center content inside of the selected step item container */
.ant-steps-item-process .ant-steps-item-container {
  display: flex;
  align-items: center; /* Vertical centering */
}

.ant-steps-item-content {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.ant-steps-item-title {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.ant-steps-item-active .ant-steps-item-title {
  color: white !important;
  font-weight: bold !important;
}
.ant-steps-item-active .ant-steps-item-icon {
  background-color: white !important;
  color: black !important;
  border: none !important;
  /* text-align: center !important; */
}

.ant-steps-item-wait .ant-steps-item-icon {
  box-shadow: 0px 8px 24px rgba(66, 47, 155, 0.1) !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
  color: white !important;
  background-color: transparent !important;
  /* border: 1px solid red !important; */
}
.ant-steps-icon {
  color: black !important;
  font-weight: bold !important;
  font-size: large !important;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background: linear-gradient(92.54deg, #8800ff, #00b2ff) !important;
}

.ant-steps-item-finish .ant-steps-icon {
  color: white !important;
  font-size: medium !important;
}
.ant-steps-item-finish .ant-steps-item-title {
  font-weight: bold !important;
  font-size: medium !important;
}

/* Form Styles */
/* Custom CSS for form inputs */
form {
  /* border: 1px solid red; */
  /* margin-top: 200%; */
  height: auto;
  width: 90%;
}
.ant-form {
  background-color: transparent;
  margin: 10% auto;
}
.form-input {
  background: #fff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

/* Custom CSS for form labels */
.form-label {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 5px;
  margin-left: 5px;
}
.form-label1 {
  margin-left: 15px;
}
/* Custom CSS for required asterisk */
.form-label-required::after {
  content: " *";
  color: red;
}

.selectorDiv {
  /* border: 1px solid red; */
  background-color: #fff;
  border-radius: 35px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  align-items: center;
  /* justify-content: center; */
  width: 40%;
  height: 48px;
  padding-left: 15px;
}

.countryIMG {
  /* border: 1px solid red; */
  margin-left: 5px;
  /* margin-right: 15px; */
  /* transform: scale(1.2); */
  filter: brightness(1.05);
}
.selectForm {
  margin-left: 20px;
  background-color: transparent;
  border: none !important;
  width: 90%;
}
.ant-select-selector,
.ant-select-open,
.ant-select-focused {
  border: none !important;
}

.left-col-form,
.right-col-form {
  /* border: 1px solid red; */
  width: 50vh;
  /* height: 500px; */
  display: flex !important;
  flex-direction: column;
  /* justify-content: space-around; */
  padding: 5px;
}

.basicInput {
  display: flex !important;
  background-color: #fff;
  border-radius: 35px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 48px;
  margin-top: 5px;
  /* border: 1px solid red; */
}
.inputformItem {
  width: 90%;
}

.buttonNXT {
  border-radius: 40px;
  background-color: #4e4dff;

  border: 1px solid #4e4dff;
  color: #fff;
  margin-top: 10px;
  cursor: pointer;
  font-weight: bold;
  width: 200px;
  height: 50px;
  font-size: large;
  margin-bottom: 50px;
}
.buttonPRV {
  border-radius: 40px;
  background-color: #30263e;
  color: #fff;
  margin-top: 10px;
  cursor: pointer;
  font-weight: bold;
  width: 200px;
  height: 50px;
  font-size: large;
  margin-bottom: 50px;
  margin-right: 10px;
}

.ant-input-disabled {
  background-color: rgba(48, 38, 62, 0.05) !important;
  border-radius: 35px !important;
  width: 65% !important;
}

.primaryphoneadd {
  border: 1px solid rgba(48, 38, 62, 0.25) !important;
  background-color: black;
}

:where(.css-dev-only-do-not-override-1ck3jst).ant-notification
  .ant-notification-notice {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}
:where(.css-dev-only-do-not-override-1ck3jst).ant-notification
  .ant-notification-notice
  .ant-notification-notice-close {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  font-size: 0.5rem;
}
:where(.css-dev-only-do-not-override-pr0fja).ant-input {
  border: none !important;
}
:where(.css-dev-only-do-not-override-pr0fja).ant-input-affix-wrapper {
  border: none !important;
}
:where(.css-dev-only-do-not-override-pr0fja).ant-picker {
  border: none !important;
}
:where(.css-dev-only-do-not-override-pr0fja).ant-input-number
  .ant-input-number-input {
  border: none !important;
}
:where(.css-dev-only-do-not-override-pr0fja).ant-input-number {
  border: none !important;
}
:where(.css-dev-only-do-not-override-pr0fja).ant-upload-wrapper
  .ant-upload-drag {
  border: none !important;
}

/* Responsive design for smaller screens */
