.servicesContainer {
  background: #f5f4fa !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.serviceMainContainer {
  width: 90%;
  margin: 10px auto;
  margin-top: 110px;
}

.categoriesContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: auto;
  width: 100%;
  gap: 10px;
  padding: 5px;
}

.categoriesContainer::-webkit-scrollbar {
  display: none;
}

.singleCategory {
  background-color: #fff;
  border-radius: 50px;
  z-index: 1;
  box-shadow: 0px 5px 8px 0px rgba(48, 38, 62, 0.05);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 20px;
  cursor: pointer;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 97px;
  min-height: 34px;
}

.serviceHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.actionsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.productListing {
  width: 100%;
  height: auto;
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr)); */

  grid-template-columns: repeat(auto-fit, minmax(12rem, 280px));

  grid-gap: 2.5rem;
  margin-top: 10px;
  margin-bottom: 20px;
  /* justify-items: center; */
}

.mainServiceBox {
  height: 200pxpx;
  width: 220px;
}
.serviceImageDiv {
  height: 150px;
  width: 220px;
  border: none;
  border-radius: 20px;
}
.serviceCategoryDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: 800;
  font-size: 14px;
}
.seerviceDurationDiv {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 800;
  justify-content: flex-start;
  align-items: center;
}
@media screen and (max-width: 900px) {
  .serviceMainContainer {
    margin-top: 0.5rem;
    margin-bottom: 15rem;
  }
}
@media (min-width: 600px) {
  .productListing {
    grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));
  }
}

@media (max-width: 600px) {
  .productListing {
    grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
  }
}
@media only screen and (max-width: 600px) {
  .productListing {
    justify-content: center;
    justify-items: center;
  }
}


.categoriesContainerMobile {
  gap: 8px;
  padding: 8px;
  overflow-x: auto;
  flex-wrap: nowrap;
  -webkit-overflow-scrolling: touch;
}

.singleCategoryMobile {
  padding: 6px 12px;
  white-space: nowrap;
}

.productListingMobile {
  padding: 0 8px;
}