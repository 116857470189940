.smart-app-banner {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #31263e;
  color: #fff;
  text-align: center;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-top: 1px solid #e8e8e8;
  left: 50%;
  transform: translateX(-50%);
}

.banner-content {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.app-logo {
  height: 40px;
  margin-right: 10px;
  border-radius: 50%;
}

.text-content {
  flex-grow: 1;
  text-align: center;
  font-size: 16px;
}

.button-group {
  display: flex;
  gap: 10px;
}

.store-button {
  width: 120px;
  text-decoration: none !important;
  background-color: #000 !important;
  border-radius: 10px;
}

.ios-button {
  margin-right: 10px;
}

.android-button {
}

.close-icon {
  font-size: 20px;
  cursor: pointer;
  color: #31263e;
  height: 40px !important;
  width: 40px !important;
}

.do-not-show-again {
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #fff;
  padding-left: 30%;
}

@media (max-width: 768px) {
  .smart-app-banner {
    padding: 5px;
    top: 0;
    bottom: unset;
    border-top: none;
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 0.4rem;
    padding-top: 28px;
  }
  .banner-content {
    flex-direction: column;
    gap: 5px;
  }
  .text-content {
    text-align: center;
  }
  .store-button {
    width: auto;
    margin-left: 2rem;
  }
  .close-icon {
    font-size: 10px;
    cursor: pointer;
    color: #31263e;
    position: absolute;
    top: 5px;
    right: 18px;
    height: 20px !important;
    width: 20px !important;
  }
  .app-logo {
    position: absolute;
    top: 45%;
    left: 50px;
    height: 30px;
    width: 30px;
  }
}
