.cartDrawer {
  background-color: #f9f9f9;
  padding: 16px;
}

.ant-drawer-header {
  background-color: #ffffff;
  border-bottom: 1px solid #eaeaea;
}

.ant-drawer-title {
  font-size: 20px;
  font-weight: 600;
  color: #333;
}

.ant-tabs-tab {
  font-size: 16px;
  font-weight: 500;
  color: #555;
  transition: color 0.3s;
}

.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #1890ff;
  font-weight: 600;
}

.ant-tabs-ink-bar {
  background-color: #1890ff;
}

.Cartitem {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 10px;
  overflow-y: auto;
  max-height: 90vh;
}

.cartItemContainer {
  display: flex;
  align-items: center;
  padding: 12px;
  background-color: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  transition: box-shadow 0.3s;
}

.cartItemContainer:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.itemDetails {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}

.itemTitle {
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin-bottom: 4px;
}

.itemCategory {
  font-size: 14px;
  color: #888;
}

.itemActions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.checkoutButton {
  background-color: #1890ff;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.checkoutButton:hover {
  background-color: #40a9ff;
}

.noItemsMessage {
  text-align: center;
  color: #888;
  font-size: 14px;
  margin-top: 20px;
}

.tabButtons {
  display: flex;
  justify-content: space-around;
  margin-top: 16px !important;
  /* margin-bottom: 16px; */
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #ccc;
}

.tabButton {
  position: relative;
  background-color: transparent;
  border: 1px solid #ccc;
  cursor: pointer;
  font-weight: bold;
  padding: 6px 12px;
  color: #30263e;
  display: flex;
  align-items: center;
  border-radius: 35px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.activeTab {
  color: #fff;
  background-color: #30263e;
}

.countOnIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(92.54deg, #8800ff, #00b2ff);
  color: #fff;
  font-weight: bold;
  width: 18px;
  height: 18px;
  font-size: 12px;
  border-radius: 50%;
  position: absolute;
  top: -10px;
  right: -8px;
}
