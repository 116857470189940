.servicesContainer {
  background: #f5f4fa !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.servicesContainer h2,
.servicesContainer h3,
.servicesContainer p {
  margin: 0;
}

.servicesContainer p {
  line-height: 1.25rem;
  word-spacing: 0.15rem;
  letter-spacing: 0.03rem;
}

.serviceMainContainer {
  width: 90%;
  margin: 10px auto;
  margin-top: 110px;
}

.serviceHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.actionsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.serviceInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}

.serviceActions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  padding: 0 20px;
  border-left: 1px solid #aaa;
}

.othersOfProvider {
  margin: 20px 0;
  padding: 20px 0;
  border-top: 1px solid #aaa;
  border-bottom: 1px solid #aaa;
}

.otherServices {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: auto;
  width: 100%;
  gap: 1rem;
  padding-top: 20px;
}

.otherServices::-webkit-scrollbar {
  display: none;
}

.boxInfoContainer {
  background: #fff;
  border-radius: 20px;
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
}

.supplierInforInner {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  padding: 10px;
  border-radius: 25px;
  background: linear-gradient(151deg, #ecd8ff 0%, #d8f3ff 60.52%) !important;
  min-width: 98%;
  margin-top: 15px;
  margin-bottom: 10px;
}


.boxInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  padding: 0 20px;
  height: 100%;
  min-height: 90px;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.boxInfo p {
  color: #666;
}

.flexRow {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 40px;
}

.flexCol {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 20px;
}

.switchBox {
  flex-direction: row;
}

.borderBox {
  border-right: 1px solid #aaa;
  padding-bottom: auto;
}

.customProvider {
  background: linear-gradient(92.54deg, #8800ff, #00b2ff);
  border-radius: 25px;
  padding: 2px;
  padding-top: 1px;
}

.customProviderText {
  padding: 8px 15px;
  border-radius: 25px;
  background: #f5f4fa;
}

.customProvider p {
  background: linear-gradient(92.54deg, #8800ff, #00b2ff);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.buttonContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  width: 100%;
  margin-top: 1rem;
}
.buttonContainer Button:nth-child(2) {
  display: flex;
  color: white;
  background-color: #31263e;
  height: 50px;
  width: 150px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 35px;
  box-shadow: 0px 10px 16px 0px rgba(48, 38, 62, 0.1);
}

.buttonContainer Button:first-child {
  display: flex;
  color: white;
  background-color: #31263e;
  height: 50px;
  width: 150px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 35px;
  box-shadow: 0px 10px 16px 0px rgba(48, 38, 62, 0.1);
}

.buttonContainer Button:last-child {
  display: flex;
  color: white;
  background-color: #4e4dff;
  height: 50px;
  width: 150px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 35px;
  box-shadow: 0px 10px 16px 0px rgba(48, 38, 62, 0.1);
}
.buttonContainer Button:last-child:hover,
.buttonContainer Button:last-child:active,
.buttonContainer Button:last-child:focus {
  display: flex;
  border: #4e4dff 1px solid !important;
  color: #4e4dff !important;
  background-color: white !important;
}
.buttonContainer Button:first-child:hover,
.buttonContainer Button:first-child:active,
.buttonContainer Button:first-child:focus {
  display: flex;
  border: #31263e 1px solid !important;
  color: #31263e !important;
  background-color: white !important;
}

.addedToCart {
  border: #31263e 1px solid !important;
  color: #31263e !important;
  background-color: white !important;
  width: 200px !important;
}
@media screen and (max-width: 900px) {
  .serviceMainContainer {
    margin-top: 0.5rem;
    margin-bottom: 15rem;
  }
}

.serviceDescription img {
  max-width: 100%;
  height: auto;
}

.selectInput {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: white;
  /* padding little above and below and sides little more  */
  padding: 0.2rem 1rem;
}

.select > div > span {
  display: flex !important;
  align-items: center !important;
  padding-right: 8px;
}

.option {
  display: flex;
  align-items: center;
}

.option .ant-select-selector {
  display: flex;
  align-items: center;
}

.varientConatiner {
  min-width: 95%;
  margin-top: 1rem;
}
.label {
  margin-bottom: 0.5rem !important;
  padding-left: 0.2rem !important;
}
.labelStyle {
  color: #6b6476;
}

.styleContent {
  color: #31263e;
  font-weight: bold;
  font-size: 16px;
}

/* cart count */

.cartCount {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 30px;
  padding: 5px 10px;
  width: 136px;
  height: 50px;
}

.sizeDiv {
display:"flex";
flex-direction:"row" !important;
border:"1px solid red";
}

.CartButton,
.CartButton2 {
  border: none;
  background: none;
  cursor: pointer;
  height: 40px !important;
  width: 40px !important;
  background-color: #f5f4f5 !important;
  border-radius: 50% !important;
  box-shadow: none !important;
}

.CartButton:hover,
.CartButton2:hover {
  border: none !important;
  background: none !important;
  cursor: pointer;
  height: 40px !important;
  width: 40px !important;
  background-color: #f5f4f5 !important;
  border-radius: 50% !important;
  box-shadow: none !important;
  outline: none !important;
}

.countDisplay {
  width: 40px;
  text-align: center;
  margin: 0 10px;
  line-height: 40px;
  font-size: 16px;
}

.collabration {
  height: 50px;
  width: 99%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
}

.collabBrand {
  font-weight: bold;
  font-size: 13px;
}

.profileImage {
  width: 35px;
  height: 35px;
  background: #f2f2f2;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #8a8a8a;
  margin-right: 1rem;
}

.colorSize{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.colorSizeInner {
  display: flex;
  flex-direction: column;
}