.eventCardContainer {
  display: flex;
  position: relative;
  width: 350px;
  max-width: 450px;
  height: 100%;
}

.leftBorder {
  width: 5px;
  background-color: #4e4dff;
  border-radius: 0px !important;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
}

.eventCard {
  display: flex;
  padding: 16px;
  margin-left: 10px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  flex: 1;
}

.smallImg {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  margin-right: 16px;
  object-fit: cover;
}

.serviceInfo {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.subTitle {
  font-size: 1em;
  font-weight: bold;
  color: #31263e;
  margin: 0;
}

.mainTitle {
  font-size: 1.2em;
  font-weight: bold;
  color: #31263e;
  margin: 0;
}

.pricingContainer {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.cardPricing {
  display: flex;
  align-items: center;
}

.currency {
  color: #97929e;
}

.priceVal {
  font-size: 1em;
  font-weight: bold;
  margin-left: 4px;
}

.separator {
  margin: 0 8px;
  color: #97929e;
}

.duration {
  color: #97929e;
}

.description {
  margin-top: 8px;
  font-size: 0.9em;
  color: #97929e;
}

.location {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.address {
  font-size: 0.9em;
  color: #31263e !important;
}

.directionButton {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 35px;
  height: 28px !important;
  width: 35px !important;
}

.completeButton {
  margin-left: auto;
}
