.servicesContainer {
  background: #f5f4fa !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.serviceMainContainer {
  width: 90%;
  margin: 10px auto;
  /* margin-top: 110px; */
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
}

.categoriesContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;
  width: 100%;
  gap: 10px;
  padding: 5px;
  /* border: 1px solid red; */
  border-radius: 35px;
}

.categoriesContainer {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.categoriesContainer::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.singleCategory {
  background-color: #fff;
  border-radius: 50px;
  z-index: 1;
  box-shadow: 0px 5px 8px 0px rgba(48, 38, 62, 0.05);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 20px;
  cursor: pointer;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 100px;
}

.serviceHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.actionsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.serviceListing {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  grid-gap: 1rem;
  margin-top: 10px;
  margin-bottom: 20px;
  /* border: 1px solid red; */
}
.locationPref {
  /* border: 1px solid red; */
  /* height: 200px; */
  margin-top: 20px;
}
.addressHomeModule {
  /* border: 1px solid red; */
}

/* CardStyles.module.css */
.cardContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  margin-bottom: 10px;
  background-color: white;
  border-radius: 8px;
  cursor: pointer;
  width: 80%;
}

.cardDetails {
  display: flex;
  flex-direction: column;
}

.cardName {
  font-weight: bold;
  margin-bottom: 5px;
  margin-left: 5px;
}

.cardInfo {
  display: flex;
  align-items: center;
  font-size: 0.9em;
  color: #555;
}

.cardBrand,
.cardNumber {
  margin-left: 5px;
  margin-right: 5px;
}

/* .radioCircle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #ccc;
  margin-right: 1rem;
} */
.radioCircle {
  border: 2px solid #ccc;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 1rem;
  background: white;
  position: relative;
}

.radioCircle.selectedCard {
  border: 0px solid #ccc;
  width: 20px;
  height: 20px;
}
/* Add styles for the + Add Credit Card button if needed */
.addButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-top: 10px;
  background-color: #f0f0f0;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
}

.cardLoadMore {
  border: none;
  outline: none;
  background: none;
  background-color: none;
  color: #4e4dff;
  font-weight: bold;
  cursor: pointer;
}

.confirmationModal > div:nth-child(2) {
  border: 3px solid white;
  border-radius: 10px !important;
  background: linear-gradient(151deg, #ecd8ff, #d8f3ff 60.52%) !important;
}

.confirmationModal > div:nth-child(2) > div button:nth-child(1) {
  background-color: #30263e !important;
  color: white !important;
  font-weight: bold !important;
  border-radius: 35px !important;
  height: 30px !important;
  width: 60px !important;
  font-weight: bold !important;
  font-size: 14px !important;
  cursor: pointer !important;
  outline: none !important;
  transition: background-color 0.3s ease !important;
}

.confirmationModal > div:nth-child(2) > div button:nth-child(2) {
  background-color: #4e4dff !important;
  color: white !important;
  font-weight: bold !important;
  border-radius: 35px !important;
  height: 30px !important;
  width: 60px !important;
  font-weight: bold !important;
  font-size: 14px !important;
  cursor: pointer !important;
  outline: none !important;
  transition: background-color 0.3s ease !important;
}

.confirmationText {
  font-weight: bold;
  font-size: 18px;
  margin-top: 10px;
}

@media screen and (max-width: 900px) {
  .serviceMainContainer {
    margin-top: 0.5rem;
    margin-bottom: 15rem;
  }
}
