/* FilterModal.module.css */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.418);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.modalOverlay.active {
  opacity: 1;
  visibility: visible;
}

.modalContainer {
  /* background-color: white; */
  /* padding: 20px; */
  border-radius: 10px;
  /* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2); */
  /* width: 50%; */
  max-width: 100%;
  /* transform: scale(0.9); */
  transition: transform 0.3s ease-in-out;
  position: relative;
  display: flex;
  height: 50px;
  justify-content: center;
  align-items: center;
}

.modalContainer.active {
  transform: scale(1);
}

.filterRow {
  /* margin-bottom: 15px; */
  display: flex;
  /* flex-direction: column; */
  gap: 5px;
  justify-content: center;
  align-items: center;
}

.FilterselectInput,
.filtertextInput {
  width: 100px;
  padding: 10px;
  border: none !important;
  outline: none !important;
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  max-width: 150px;
  cursor: pointer;
  background: transparent;
}
.filterContainer {
  /* margin-top: 2rem; */
  /* border: 1px solid red; */
  display: flex;
  width: 100%;
  height: 50px;
  gap: 2rem;
  justify-content: center;
  align-items: center;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 1rem;
}

.filterButton,
.resetButton {
  /* padding: 0 0.5rem; */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
  height: 30px;
  width: 60px;
}

.filterButton {
  background-color: rgb(78, 77, 255);
  color: white;
  border-radius: 25px;
}

.resetButton {
  background-color: #31263e;
  color: white;
  border-radius: 25px;
}
.cancelButton {
  cursor: pointer;
}
.FilterDatePicker {
  border: 0px solid red !important;
  box-shadow: none !important;
  color: #31263e !important;
}
.FilterDatePicker:hover {
  border: none !important;
  outline: none !important;
}
