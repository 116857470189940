.checkbox-container {
  display: inline-block;
  cursor: pointer;
}

.checkbox-placeholder {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #ddd; /* Light gray color for the placeholder */
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2); /* Adding a slight shadow for depth */
}
