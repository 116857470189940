.pageContainer {
  display: flex;
  flex-direction: row;
}

.searchPage {
  width: 100%;
  padding: 20px;
  background-color: #f5f4fa !important;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.singleCategory {
  background-color: #fff;
  border-radius: 50px;
  z-index: 1;
  box-shadow: 0px 5px 8px 0px rgba(48, 38, 62, 0.05);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 20px;
  cursor: pointer;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 97px;
  max-width: 120px;
  min-height: 34px;
}

/* New CSS */
.inputBox {
  display: flex;
  align-items: center;
  padding-left: 1rem;
  border-radius: 40px;
  border: 0.5px solid rgba(48, 38, 62, 0.05);
  box-shadow: 0px 5px 8px 0px rgba(48, 38, 62, 0.05);
  background: #fff;
  width: 100%;
  max-width: 600px;
}

.searchIcon {
  margin-right: 10px;
}

.inputField {
  flex: 1;
  padding: 10px 12px;
  font-size: 1rem;
  outline: none;
  border: none;
  width: 100%;
}

.filterIcon {
  transition: transform 0.3s ease;
  /* box shadow light black */
  box-shadow: none !important;
  border-radius: 35px !important;
  background-color: #fff !important;
  border: none !important;
  margin-right: 0.2rem;
}

.filterIcon .icon-container {
  cursor: pointer;
}

.filterIcon.clicked {
  transform: rotate(90deg);
}

.searchBtn {
  margin-left: 10px;
}

/* Old CSS */

.searchBar {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  width: 100%;
}

.searchInput {
  width: 60%;
  margin-right: 10px;
}

.filterCapsules {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 50px;
  flex-wrap: nowrap;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.filterCapsules::-webkit-scrollbar {
  display: none;
}

.backButton {
  border: none !important;
  border-radius: 35px !important;
  background-color: #fff !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  box-shadow: rgba(48, 38, 62, 0.05) 0px 5px 8px 0px !important;
  margin-right: 0.2rem;
  padding-left: 20px;
  position: absolute;
  left: 20px;
  cursor: pointer;
}

.filterCapsule {
  padding: 8px 15px;
  background-color: #eee;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-right: 10px;
}

.activeFilter {
  /* background-color: #; */
  background: linear-gradient(92.54deg, #8800ff, #00b2ff) !important;
  font-weight: bold !important;
  color: white;
}

.resultsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  width: 100%;
  max-height: calc(80vh - 250px);
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 250px;
  scrollbar-width: none;
}

.resultsContainer::-webkit-scrollbar {
  display: none;
}

.card {
  width: 300px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  max-height: 400px;
}

.cardImage {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 10px;
}

.placeholder {
  width: 100%;
  height: 250px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  background-color: #ddd;
  color: #30263e;
  font-size: 24px;
  text-transform: uppercase;
  border-radius: 10px !important;
}

.carouselArrow {
  background: rgba(255, 255, 255, 0.25) !important;
  backdrop-filter: blur(10px) !important;
  padding: 10px !important;
  border-radius: 50% !important;
  font-size: 18px !important;
  color: #30263e !important;
}

.pagination {
  margin: auto !important;
  margin-top: 0.5rem;
  justify-content: center !important;
}

.pagination li {
  /* border: 1px solid red !important; */
  border-radius: 35px !important;
}

.pagination .ant-pagination-item-active {
  background-color: #30263e !important;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  margin-bottom: 0.5rem;
  border-bottom: #e1dfe7 2px solid;
}

.logo {
  height: 40px;
  margin-left: 2rem;
}

.navLinks {
  display: flex;
  gap: 20px;
  margin-right: 2rem;
}

.navLinks a {
  text-decoration: none;
  color: #333;
  font-weight: 500;
}

.navLinks a:hover {
  color: #1890ff;
}

.retryButton {
  border-radius: 35px !important;
  background-color: #31263e !important;
  color: white !important;
}

.retryButton:hover {
  color: white !important;
  background-color: #4e4dff !important;
}

@media (max-width: 576px) {
  .navLinks {
    display: none;
  }

  .bottomNav {
    display: flex;
    justify-content: space-around;
    padding: 10px 0;
    background-color: #f5f5f5;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1);
    z-index: 999;
  }

  .pagination {
    height: 400px !important;
    width: 100% !important;
  }

  .navItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #333;
    font-weight: 500;
  }

  .navItem span {
    font-size: 12px;
    margin-top: 4px;
  }

  .navItem:hover {
    color: #1890ff;
  }

  .header {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 0.5rem;
  }
}

.hidden {
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity 0.3s, transform 0.3s;
}

/* here */
/* App.module.css */
.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
  height: auto;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 30px 10px 20px;
  margin-bottom: 0.5rem;
  border-bottom: #e1dfe7 2px solid;
  transition: transform 0.3s ease-in-out;
  z-index: 100;
}

.hideHeader {
  transform: translateY(-100%);
}

.main {
  margin-top: 10px;
  flex-grow: 1;

  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
}

.mainHide {
  margin-top: 0px;
  flex-grow: 1;

  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
}

.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
  padding: 1rem;
  margin: 0 auto;
  margin-bottom: 300px;
}

.box {
  flex: 1 1 calc(33.333% - 20px);
}

.boxLast {
  flex: 1 1 calc(33.333% - 20px);
  margin-right: 0;
  background-color: #31263e;
  color: white;
  text-align: center;
  margin-top: 1rem;
  border-radius: 35px;
  padding: 1rem;
  font-size: 1rem;
  cursor: pointer;
}

.tableIcon {
  margin-right: 0.2rem;
}

.toggleButton {
  z-index: 100;
  background-color: #fff !important;
  border: none !important;
  border-radius: 5px;
}

.toggleButton:hover {
  background-color: #fff !important;
  color: #31263e !important;
  border: none !important;
}

@media (max-width: 768px) {
  .box {
    flex: 1 1 calc(50% - 20px);
  }
  .filterCapsules {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 85px;
  }
}

@media (max-width: 480px) {
  .box {
    flex: 1 1 100%;
  }
}

@media (max-width: 576px) {
  .navLinks {
    display: none;
  }

  .bottomNav {
    display: flex;
    justify-content: space-around;
    padding: 10px 0;
    background-color: #f5f5f5;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1);
    z-index: 999;
  }

  .navItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #333;
    font-weight: 500;
  }

  .navItem span {
    font-size: 12px;
    margin-top: 4px;
  }

  .navItem:hover {
    color: #1890ff;
  }

  .header {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 0.5rem;
  }

  .inputBox {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
    /* border: 1px solid red; */
    max-width: 400px;
  }

  .card {
    width: 95vw;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    max-height: 400px;
    margin: auto !important;
    margin-right: 40px !important;
  }

  .resultsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    width: 100%;
    max-height: calc(100vh - 0px);
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 250px;
    scrollbar-width: none;
  }

  .header {
    margin-left: 0;
    margin-right: 0;
  }
  .logo {
    margin-left: 0;
  }
}

.hidden {
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity 0.3s, transform 0.3s;
}

/* here */
/* App.module.css */
.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
  height: auto;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 30px 10px 20px;
  margin-bottom: 0.5rem;
  border-bottom: #e1dfe7 2px solid;
  transition: transform 0.3s ease-in-out;
  z-index: 100;
  margin-left: 1rem;
  margin-right: 1rem;
}

.hideHeader {
  transform: translateY(-100%);
}

.main {
  margin-top: 10px;
  flex-grow: 1;

  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
}

.mainHide {
  margin-top: 0px;
  flex-grow: 1;

  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
}

.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
  padding: 1rem;
  margin: 0 auto;
  margin-bottom: 300px;
}

.box {
  flex: 1 1 calc(33.333% - 20px);
}

.boxLast {
  flex: 1 1 calc(33.333% - 20px);
  margin-right: 0;
  background-color: #31263e;
  color: white;
  text-align: center;
  margin-top: 1rem;
  border-radius: 35px;
  padding: 1rem;
  font-size: 1rem;
  cursor: pointer;
}

.tableIcon {
  margin-right: 0.2rem;
}

.toggleButton {
  z-index: 100;
  background-color: #fff !important;
  border: none !important;
  border-radius: 5px;
}

.toggleButton:hover {
  background-color: #fff !important;
  color: #31263e !important;
  border: none !important;
}

@media (max-width: 768px) {
  .box {
    flex: 1 1 calc(50% - 20px);
  }
}

@media (max-width: 480px) {
  .box {
    flex: 1 1 100%;
  }
}
