.breakCard {
  background: repeating-linear-gradient(
    135deg,
    rgba(255, 255, 255, 1.5),
    rgba(255, 255, 255, 1.5) 5px,
    transparent 5px,
    transparent 10px
  );
  padding: 16px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  position: relative;
  border: none;
  width: 100%;
  height: 100%;
}

.breakDuration {
  background: rgba(255, 255, 255, 0.7);
  padding: 4px 8px;
  border-radius: 35px;
  font-size: 1.2em;
  margin-right: 16px;
  color: #31263e;
  border: 2px solid #31263e;
  font-weight: bold;
}

.breakTitle {
  font-size: 2em;
  font-weight: bold;
  color: #31263e;
}
