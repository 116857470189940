@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;700&display=swap");

* {
  font-family: "Ubuntu", sans-serif !important;
}

body {
  margin: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, "Ubuntu", "Segoe UI", "Roboto",
    "Oxygen", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  /* cursor: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24"%3E%3Cdefs%3E%3ClinearGradient id="grad" x1="0%25" y1="0%25" x2="100%25" y2="100%25"%3E%3Cstop offset="0%25" style="stop-color:%23a855f7;stop-opacity:1"%3E%3C/stop%3E%3Cstop offset="100%25" style="stop-color:%233b82f6;stop-opacity:1"%3E%3C/stop%3E%3C/linearGradient%3E%3C/defs%3E%3Cpath fill="url(%23grad)" stroke="%23FFF" stroke-width="1" d="M5.5 3.21V20.8c0 .45.54.67.85.35l4.86-4.86a.5.5 0 0 1 .35-.15h6.87a.5.5 0 0 0 .35-.85L6.35 2.85a.5.5 0 0 0-.85.35Z"%3E%3C/path%3E%3C/svg%3E'),
    auto; */
  /* background-color: #f5f4fa !important; */
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(255, 255, 255);
  border-radius: 10px;
}

iframe {
  border: none !important;
}

.layout,
.layout1 {
  width: 100%;
  min-height: fit-content;
  background-repeat: round;
  background-origin: content-box;
  /* background-image: url("./assests/OctopusSideImgMain.png"); */
  /* background-attachment: fixed; */
  background-size: cover;
}

/* :where(.css-dev-only-do-not-override-1c0na6j).ant-carousel .slick-next::after {
  content: none !important;
  display: none !important;
  height: 0 !important;
  width: 0 !important;
}

:where(.css-dev-only-do-not-override-1c0na6j).ant-carousel .slick-prev::after {
  content: none !important;
  display: none !important;
  height: 0 !important;
  width: 0 !important;
} */

.ant-carousel .slick-next::after {
  content: none !important;
  display: none !important;
  height: 0 !important;
  width: 0 !important;
}

.ant-carousel .slick-prev::after {
  content: none !important;
  display: none !important;
  height: 0 !important;
  width: 0 !important;
}
/* .ant-input-affix-wrapper {
  box-shadow: 1px 2px 5px #808080ad !important;
} */
/* .ant-select-selector {
  box-shadow: 1px 2px 5px #808080ad !important;
} */
.ant-picker {
  /* box-shadow: 1px 2px 5px #808080ad !important; */
}
.ant-input {
  /* box-shadow: 1px 2px 5px #808080ad !important; */
}
.ant-input-affix-wrapper > .ant-input {
  box-shadow: none !important;
}

/* .ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper:focus {
  box-shadow: 1px 2px 5px #808080ad !important;
} */

.customLayoutSideContainer {
  /* background: url("./assests/lineargradient.png"); */
  display: flex;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  background-origin: border-box;
  /* overflow-y: auto; */
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-position: center;
  /* background-attachment: fixed; */
  background-size: cover;
  width: 100%;
  align-items: start;
  justify-content: space-evenly;
  flex-direction: row;
}
.customLayoutSideContainer1 {
  /* background: url("./assests/lineargradient.png"); */
  /* background: linear-gradient(160deg, #8200ff 0%, #00b0ff 68.93%); */
  display: flex;
  height: 100vh;
  overflow: auto;
  background-origin: border-box;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  background-position: center;
  /* background-attachment: fixed; */
  background-size: cover;
  width: 100%;
  align-items: start;
  justify-content: space-evenly;
  flex-direction: row;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.octopusMainLogoClass {
  display: none;
}

.ant-select-selection-item {
  display: flex !important;
}

.noSpinners::-webkit-inner-spin-button,
.noSpinners::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.noSpinners {
  -moz-appearance: textfield; /* Firefox */
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.cc__icon {
  border: 1px solid red !important;
}

.ant-pagination-item-active {
  background-color: #30263e !important;
  color: white !important;
}

.ant-pagination-item-active a {
  color: white !important;
}

.ant-tabs-nav-list {
  display: flex !important;
  flex-wrap: nowrap !important;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.smallimg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  width: 50px;
  height: 50px;
}

/* .{

} */

.ant-tabs-nav-wrap {
  flex-wrap: nowrap;
  padding-left: 1rem !important;
}

.ant-tabs-tab {
  flex: 1;
  text-align: center;
  white-space: nowrap;
}

/* Responsive Styles */
@media (max-width: 1370px) {
  /* … */
  .customLayoutSideContainer {
    /* background: url("./assests/lineargradient.png"); */
    margin-bottom: 20px;
    padding-bottom: 70px;
    overflow-x: hidden;
  }
  .customLayoutSideContainer1 {
    /* background: url("https://octopus-platform-assets.s3.me-south-1.amazonaws.com/web_homepage/Octopus_homepage_animation.mp4"); */
    margin-bottom: 20px;
    padding-bottom: 70px;
    overflow-x: hidden;
  }
}

@media screen and (max-width: 900px) {
  .customLayoutSideContainer {
    padding-bottom: 10rem;
  }

  .octopusMainLogoClass {
    display: block !important;
  }
}
/* Add more media queries as needed for other screen sizes */
