.verifyDocumentsContainer {
  height: 60vh;
  padding: 20px;
  /* margin-top: 80px; */
  margin: auto;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  /* overflow-y: scroll; */
}

.verifyDocumentsContainerForm {
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  /* justify-content: center; */
}

.verifyDocumentsContainerForm > input {
  width: 100%;
}

.skipBtn {
  position: absolute;
  top: 30px;
  right: 50px;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}
