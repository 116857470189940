.custom-radio-button {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.custom-radio-hidden {
  opacity: 0;
  position: absolute;
}

.custom-radio-unchecked {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid #d9d9d9;
  box-sizing: border-box;
}

.custom-radio-svg {
  /* Additional styling for SVG if needed */
}

.custom-radio-label {
  margin-left: 8px;
}

.custom-radio-button {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.custom-radio-labels {
  display: flex;
  flex-direction: column;
  margin-left: 8px;
}

.custom-radio-primary-label {
  font-weight: bold;
}

.custom-radio-secondary-label {
  font-size: smaller;
  color: #666; /* Adjust color as needed */
}
